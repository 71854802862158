import utils from "./utils.js";

import Answer from "../models/Answer";
import Answers from "../models/Answers";

export default {
  state: {
    answers: null,
    studentAnswers: {}
  },
  getters: {
    getAnswers: (state) => () => {
      return state.answers;
    },
    getWaypointAnswers: (state) => (waypoint) => {
      if (state.answers && waypoint) {
        return state.answers.filter(answer => answer.answerable_type == "Waypoint" && answer.answerable_id == waypoint.id);
      }
      return [];
    }
  },
  actions: {
    async loadAnswers({commit}, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let waypoint = utils.try(params, "waypoint", null);
        let user = utils.try(params, "user", null);
        if (refresh || (await Answers.count(waypoint, user, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let answers = await Answers.load(waypoint, user, search, offset, limit, deleted);
          if (answers && answers.length > 0) {
            await answers.store();
          }
        }
        let answers = await Answers.restore(waypoint, user, search, 0, offset + limit);
        commit("setAnswers", answers);
        return Promise.resolve(answers);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveAnswer({
      commit, rootGetters, state
    }, params = {}) {
      try {
        let _answer = new Answer();
        _answer.user_id = utils.try(params, "user_id", rootGetters.getUserId());
        _answer.question_id = utils.try(params, "question_id", null);
        _answer.answerable_id = utils.try(params, "answerable_id", null);
        _answer.answerable_type = utils.try(params, "answerable_type", null);
        _answer.value = utils.try(params, "value", "");

        /*
        Checking if the selected answer is the same as the previous value,
        if so, we don't need to save it again.
        */
        const foundAnswer = state.answers?.find(answer => answer.question_id === _answer.question_id);
        if (foundAnswer?.value === _answer.value) {
          return;
        }

        let answer = await _answer.save();
        await answer.store();
        commit("setAnswer", answer);
        return Promise.resolve(answer);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {
    setAnswers: (state, answers) => {
      state.answers = answers;
    },
    setAnswer: (state, answer) => {
      if (state.answers) {
        const index = state.answers.findIndex(_answer => _answer.id === answer.id);
        if (index > -1) {
          state.answers.splice(index, 1, answer);
        }
        else {
          state.answers.push(answer);
        }
      }
    },
    setStudentAnswer: (state, params) => {
      if (state.studentAnswers) {
        const answer = state.studentAnswers.find(a => a.waypoint_id === params.waypoint_id && a.student_id === params.student_id);
        answer.note = params.note;
        answer.note_author = params.note_author;
      }
    },
    resetAnswers: (state) => {
      state.answers = null;
    }
  }
};
