import Model from "./Model";
import User from "./User";
import Server from "../services/Server";
import Analytics from "../services/Analytics";

export default class Password extends Model {

  constructor(data = {}) {
    super(data);
  }

  static async accept(token, name, password, email = null, image = null) {
    let url = process.env.VUE_APP_API_URL + "/invitations/accept.json";
    let response = await Server.post(url, {
      token: token,
      name: name,
      password: password,
      email: email,
      image: image
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      if (user.added_classroom) {
        let classroomName = user.added_classroom.name;
        window.location.href = `/home?added_to_classroom=true&&classroom_name=${classroomName}`;
      }
      else {
        window.location.href = "/";
      }
      return user;
    }
    return null;
  }

  static async reinvite(user_id) {
    let url = process.env.VUE_APP_API_URL + `/invitations/${user_id}/reinvite.json`;
    let response = await Server.post(url, {});
    return response != null;
  }

  static async resend(email) {
    let url = process.env.VUE_APP_API_URL + "/invitations/resend.json";
    let response = await Server.post(url, {
      email: email
    });
    return response != null;
  }
}
