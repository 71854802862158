import Model from "./Model";

export default class Assignment extends Model {

  id = null;
  type = null;
  lesson_id = null;
  lesson_name = null;
  toolkit_id = null;
  classroom_id = null;
  classroom_name = null;
  due_date = null;
  pending_student_ids = null;
  submitted_student_ids = null;
  reviewed_student_ids = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`lesson-assignments/${this.lesson_id}/${this.classroom_id}/${this.id}`);
  }

  static async restore(lesson_id, classroom_id, id) {
    return Model._restore(Assignment, `lesson-assignments/${lesson_id}/${classroom_id}/${id}`);
  }

  async save() {
    let path = this.id && this.id.length > 0 ? `assignments/${this.id}.json` : "assignments.json";
    return this._save(Assignment, path, {
      lesson_id: this.lesson_id,
      classroom_id: this.classroom_id,
      due_date: this.due_date || "",
      pending_student_ids: this.pending_student_ids,
      submitted_student_ids: this.submitted_student_ids,
      reviewed_student_ids: this.reviewed_student_ids
    });
  }

  async delete() {
    await this._delete(`assignments/${this.id}.json`);
    await this._remove(`lesson-assignments/${this.lesson_id}/${this.classroom_id}/${this.id}`);
    return null;
  }

}