import Models from "./Models";
import Assignment from "./Assignment";

export default class Assignments extends Models {

  constructor(models = []) {
    super(Assignments, models);
  }

  static async clear() {
    return Models._clear("lesson-assignments");
  }

  static async count(lesson, search = "", offset = 0, limit = 10) {
    let path = lesson ? `lesson-assignments/${lesson}/` : "lesson-assignments/";
    return Models._count(path, search, offset, limit, "updated_at", "question_name");
  }

  static async restore(lesson, search = "", offset = 0, limit = 10) {
    let path = lesson ? `lesson-assignments/${lesson}/` : "lesson-assignments/";
    return Models._restore(Assignments, Assignment, path, search, offset, limit, "updated_at", "question_name");
  }

  static async load(student, lesson, classroom_id, search = "", offset = 0, limit = 10, deleted = false) {
    let path = lesson?.length > 0 ? `lessons/${lesson}/assignments.json` : "assignments.json";
    if (classroom_id && classroom_id !== "all") {
      path = path + `?classroom_id=${classroom_id}`;
    }
    if (student?.length > 0) {
      path = path + `?student_id=${ student }`;
    }
    return Models._load(Assignments, Assignment, path, search, offset, limit, deleted);
  }

  async loadAssignments(params) {
    const path = "/assignments.json";
    const response = await this._getWithPagination(path, params);
    return response;
  }
}