import Model from "./Model";
import Students from "./Students";

export default class Classroom extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  alternate_identifier = null;
  description = null;
  icon = null;
  image = null;
  years = [];
  school_id = null;
  school_name = null;
  toolkits_count = null;
  teachers_count = null;
  students_count = null;
  announcements_count = null;
  google_event_id = null;
  google_meet_url = null;
  min_grade = null;
  max_grade = null;
  created_at = null;
  updated_at = null;
  custom_class = null;
  tier = null;
  access_id = null;
  students = new Students();
  exclude_toolkits = [];
  teacher_ids = [];
  student_ids = [];

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["students"]) {
      this.students = new Students(data["students"]);
    }
    else {
      this.students = new Students();
    }
  }

  async store() {
    return this._store(`classrooms/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Classroom, `classrooms/${id}`);
  }

  static async load(id, filters = {}) {
    const urlParams = this.encodeParams(filters);
    return Model._load(Classroom, `classrooms/${id}.json?${urlParams}`);
  }

  async save(image_data = null, remove_image = null) {
    if (this.id && this.id.length > 0) {
      return this._save(Classroom, `schools/${this.school_id}/classrooms/${this.id}.json`, {
        id: this.id,
        school_id: this.school_id,
        name: this.name,
        title: this.title,
        alternate_identifier: this.alternate_identifier,
        description: this.description,
        min_grade: this.min_grade,
        max_grade: this.max_grade,
        exclude_toolkits: this.exclude_toolkits,
        image: image_data,
        remove_image: remove_image,
        custom_class: this.custom_class,
        tier: this.tier
      });
    }
    else {
      return this._save(Classroom, `schools/${this.school_id}/classrooms.json`, {
        school_id: this.school_id,
        name: this.name,
        title: this.title,
        alternate_identifier: this.alternate_identifier,
        description: this.description,
        min_grade: this.min_grade,
        max_grade: this.max_grade,
        exclude_toolkits: this.exclude_toolkits,
        image: image_data,
        remove_image: remove_image,
        custom_class: this.custom_class,
        tier: this.tier
      });
    }
  }

  async delete() {
    await this._delete(`classrooms/${this.id}.json`);
    await this._remove(`classrooms/${this.id}`);
    return null;
  }

  async addTeacher(teacher_id) {
    let path = this.school_id ? `schools/${this.school_id}/classrooms/${this.id}/add.json` : `classrooms/${this.id}/add.json`;
    return this._post(Classroom, path, {
      teacher_id: teacher_id
    });
  }

  async removeTeacher(teacher_id) {
    let path = this.school_id ? `schools/${this.school_id}/classrooms/${this.id}/remove.json` : `classrooms/${this.id}/remove.json`;
    return this._post(Classroom, path, {
      teacher_id: teacher_id
    });
  }

  async addStudent(student_id) {
    let path = this.school_id ? `schools/${this.school_id}/classrooms/${this.id}/add.json` : `classrooms/${this.id}/add.json`;
    return this._post(Classroom, path, {
      student_id: student_id
    });
  }

  async removeStudent(student_id) {
    let path = this.school_id ? `schools/${this.school_id}/classrooms/${this.id}/remove.json` : `classrooms/${this.id}/remove.json`;
    return this._post(Classroom, path, {
      student_id: student_id
    });
  }

}
