import Server from "../services/Server";
import Storage from "../services/Storage";

export default class Model {

  constructor(data = {}) {
    Object.assign(this, data);
  }

  async remove() {
  }

  async _remove(key) {
    return await Storage.remove(key);
  }

  async _store(key) {
    if (this.deleted_at && this.deleted_at.length > 0) {
      await Storage.remove(key);
      return null;
    }
    else {
      await Storage.set(key, this);
      return this;
    }
  }

  static async _restore(modelClass, key) {
    let value = await Storage.get(key);
    if (value) {
      let model = new modelClass(value);
      return model;
    }
    return null;
  }

  static async _load(modelClass, path, params = {}) {
    let data = await Server.get(path, params);
    if (data) {
      let model = new modelClass(data);
      return model;
    }
    return null;
  }

  async _save(modelClass, path, params = {}) {
    if (this.id != null) {
      let data = await Server.put(path, params);
      if (data) {
        let model = new modelClass(data);
        return model;
      }
      return null;
    }
    else {
      let data = await Server.post(path, params);
      if (data) {
        let model = new modelClass(data);
        return model;
      }
      return null;
    }
  }

  async _get(path, params = {}) {
    let data = await Server.get(path, params);
    return data;
  }

  async _getWithPagination(path, params = {}) {
    let data = await Server.getWithPagination(path, params);
    return data;
  }

  async _post(modelClass, path, params = {}, customHeaders) {
    let data = await Server.post(path, params, customHeaders);
    if (data) {
      let model = new modelClass(data);
      return model;
    }
    return null;
  }

  async _put(modelClass, path, params = {}) {
    let data = await Server.put(path, params);
    if (data) {
      let model = new modelClass(data);
      return model;
    }
    return null;
  }

  async _patch(modelClass, path, params = {}) {
    let data = await Server.patch(path, params);
    if (data) {
      let model = new modelClass(data);
      return model;
    }
    return null;
  }

  async _delete(path) {
    return await Server.delete(path);
  }

  static path(...components) {
    let _path = [];
    for (let component of components) {
      if (component != null) {
        _path.push(component);
      }
    }
    return _path.join("/");
  }

  static params(params) {
    return Object.keys(params).map(key => key + "=" + params[key]).join("&");
  }

  static encodeParams(params) {
    let str = [];
    for (let p in params) {
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    }
    return str.join("&");
  }
}