import axios from "axios";
import Storage from "./Storage";

export default class Server {

  static activeCalls = [];

  static initialize() { }

  static async get(path, params = {}, responseType = "json") {
    try {
      let url = await this.url(path, params);
      let headers = await this.headers("application/json", responseType);
      let response = await axios.get(url, headers);
      return response.data;
    }
    catch (error) {
      if (error.response?.status == 401 && error.response?.data?.error === "Signature has expired") {
        window.location.href = "/logout";
      }
      else {
        console.error(`GET ${path}`, error);
      }
      throw error;
    }
  }

  // TODO: replace all get calls with this
  static async getWithPagination(path, params = {}) {
    try {
      let url = await this.url(path, params);
      let headers = await this.headers();
      let response = await axios.get(url, headers);
      return {
        data: response.data,
        pagination: {
          currentPage: parseInt(response.headers["current-page"]),
          pageItems: parseInt(response.headers["page-items"]),
          totalCount: parseInt(response.headers["total-count"]),
          totalPages: parseInt(response.headers["total-pages"])
        }
      };
    }
    catch (error) {
      console.error(`GET ${path}`, error);
      throw error;
    }
  }

  static async post(path, params = {}, customHeaders) {
    try {
      let url = await this.url(path);
      let headers = customHeaders || await this.headers();
      let response = await axios.post(url, params, headers);
      return response.data;
    }
    catch (error) {
      console.error(`POST ${path}`, error);
      throw error;
    }
  }

  static async put(path, params = {}) {
    try {
      let url = await this.url(path);
      let headers = await this.headers();
      let response = await axios.put(url, params, headers);
      return response.data;
    }
    catch (error) {
      console.error(`PUT ${path}`, error);
      throw error;
    }
  }

  static async patch(path, params = {}) {
    try {
      let url = await this.url(path);
      let headers = await this.headers();
      let response = await axios.patch(url, params, headers);
      return response.data;
    }
    catch (error) {
      console.error(`PATCH ${path}`, error);
      throw error;
    }
  }

  static async delete(path) {
    try {
      let url = await this.url(path);
      let headers = await this.headers();
      let response = await axios.delete(url, headers);
      return response.data;
    }
    catch (error) {
      console.error(`DELETE ${path}`, error);
      throw error;
    }
  }

  static async url(path, params = {}) {
    let url = null;
    if (path.startsWith("http")) {
      url = new URL(path);
    }
    else if (path.startsWith("/") || process.env.VUE_APP_API_URL.endsWith("/")) {
      url = new URL(process.env.VUE_APP_API_URL + path);
    }
    else {
      url = new URL(process.env.VUE_APP_API_URL + "/" + path);
    }
    if (params && Object.keys(params).length > 0) {
      for (let key of Object.keys(params)) {
        url.searchParams.set(key, params[key]);
      }
    }
    return url.toString();
  }

  static async headers(contentType = "application/json", responseType = "json") {
    let value = await Storage.get("user");
    if (value && value.token) {
      return {
        responseType: responseType,
        headers: {
          "Content-Type": contentType,
          Authorization: `Bearer ${value.token}`
        }
      };
    }
    return {};
  }

}
