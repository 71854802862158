import Model from "./Model";

export default class Response extends Model {

  id = null;
  type = null;
  value = null;
  values = null;
  secret = false;
  image = null;
  image_url = null;
  image_data = null;
  toolkit_id = null;
  chapter_id = null;
  lesson_id = null;
  lesson_name = null;
  section_id = null;
  section_name = null;
  school_id = null;
  user_id = null;
  student_name = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    let path = Model.path("responses", this.toolkit_id, this.lesson_id, this.section_id, this.id);
    return this._store(path);
  }

  static async restore(toolkit, lesson, section, id) {
    let path = Model.path("responses", toolkit, lesson, section, id);
    return Model._restore(Response, path);
  }

  static async load(toolkit, lesson, section, id) {
    let path = `toolkits/${toolkit}/lessons/${lesson}/sections/${section}responses/${id}.json`;
    return Model._load(Response, path);
  }

  async save() {
    return this._save(Response, "responses.json", {
      toolkit_id: this.toolkit_id,
      chapter_id: this.chapter_id,
      lesson_id: this.lesson_id,
      section_id: this.section_id,
      type: this.type || "Response",
      value: this.value,
      secret: this.secret,
      image: this.image_url || this.image_data
    });
  }

}