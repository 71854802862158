import dayjs from "dayjs";

import utils from "./utils";

import Waypoint from "@/models/Waypoint";
import Waypoints from "@/models/Waypoints";

export default {
  state: {
    waypoints: [],
    waypoint: null
  },
  getters: {
    waypoints(state) {
      if (state.waypoints?.length > 0) {
        return state.waypoints.filter(waypoint => waypoint.active === true);
      }
      return state.waypoints;
    },
    getTodaysWaypoints: (state) => () => {
      if (state.waypoints && state.waypoints.length > 0) {
        let today = dayjs(new Date()).format("YYYY-MM-DD");
        return state.waypoints.filter(waypoint => {
          const isActive = waypoint.active == true;
          const inDateRange = waypoint.started_on && waypoint.started_on <= today && waypoint.finished_on && waypoint.finished_on >= today;
          const isNotCompleted = (typeof waypoint.completed === "undefined" || waypoint.completed === null || waypoint.completed.length === 0);
          return isActive && inDateRange && isNotCompleted;
        });
      }
      return state.waypoints;
    },
    getWaypoint: (state) => () => {
      return state.waypoint;
    }
  },
  actions: {
    async loadWaypoints({commit}, params = {}) {
      try {
        let filters = utils.try(params, "filters", false);
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 50);
        let waypoints = refresh || filters ? [] : await Waypoints.restore(search, 0, offset + limit);
        if (waypoints == null || waypoints.length == 0) {
          let deleted = utils.try(filters, "deleted", false);
          waypoints = await Waypoints.load(filters, search, offset, limit, deleted);
          waypoints = waypoints.sort((a, b) => new Date(a.finished_on) - new Date(b.finished_on));
          if (waypoints && waypoints.length > 0) {
            await waypoints.store();
          }
        }
        waypoints = await Waypoints.restore(search, 0, offset + limit);
        commit("setWaypoints", waypoints);
        return Promise.resolve(waypoints);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadWaypoint({ commit }, params = {}) {
      try {
        const waypoint = await Waypoint.load(params.waypoint);
        commit("setWaypoint", waypoint);
        return Promise.resolve(waypoint);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async exportWaypoint({ commit }, params) {
      try {
        let waypoint = await Waypoint.restore(params.waypoint);
        await waypoint.export();
        return Promise.resolve(null);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async completeWaypoint({ commit }, id) {
      try {
        const waypoint = await Waypoint.complete(id);
        return Promise.resolve(waypoint);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async submitNote({ commit }, params) {
      try {
        const waypoint = await Waypoint.submitNote(params);
        return Promise.resolve(waypoint);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async clearWaypoints({ commit }, params = {}) {
      await Waypoints.clear();
      commit("resetWaypoints");
      return Promise.resolve();
    }
  },
  mutations: {
    setWaypoints: (state, waypoints) => {
      state.waypoints = waypoints;
    },
    setWaypoint: (state, waypoint) => {
      state.waypoint = waypoint;
    },
    resetWaypoints: (state) => {
      state.waypoints = null;
      state.waypoint = null;
    }
  }
};