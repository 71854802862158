module.exports = {
  try: function(params, key, fallback) {
    return params && params.hasOwnProperty(key) && params[key] != null ? params[key] : fallback;
  },
  setUserStoreKey(state, {
    key, userId, value, subkey
  }) {
    !(userId in state[key]) && (state[key][userId] = {});
    if (subkey) {
      !(subkey in state[key][userId]) && (state[key][userId][subkey] = {});
      state[key][userId][subkey] = value;
    }
    else {
      state[key][userId] = value;
    }
  }
};