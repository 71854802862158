import Models from "./Models";
import Assistant from "./Assistant";

export default class Assistants extends Models {

  constructor(models = []) {
    super(Assistant, models);
  }

  static async clear() {
    return Models._clear("assistants");
  }

  static async count(school_id = null, search = "", offset = 0, limit = 10) {
    if (school_id && school_id.length > 0) {
      return Models._count("assistants/", school_id, offset, limit, "name", "school_ids");
    }
    return Models._count("assistants/", search, offset, limit, "name", "name");
  }

  static async restore(school_id = null, search = "", offset = 0, limit = 10) {
    if (school_id && school_id.length > 0) {
      if (search && search.length > 0) {
        let assistants = await Models._restore(Assistants, Assistant, "assistants/", school_id, offset, 100, "name", "school_ids");
        return assistants.filter(assistant => assistant.name.toLowerCase().indexOf(search.toLowerCase()) != -1).slice(offset, offset + limit);
      }
      return Models._restore(Assistants, Assistant, "assistants/", school_id, offset, limit, "name", "school_ids");
    }
    return Models._restore(Assistants, Assistant, "assistants/", search, offset, limit, "name", "name");
  }

  static async load(school_id = null, search = "", offset = 0, limit = 10, deleted = false) {
    let path = school_id && school_id.length > 0 ? `schools/${school_id}/users?type=assistant.json` : "users.json?type=assistant";
    return Models._load(Assistants, Assistant, path, search, offset, limit, deleted);
  }

  static async invite(school_id, assistants) {
    return Models._create(Assistants, Assistant, `schools/${school_id}/assistants/bulk.json`, {
      assistants: assistants
    });
  }

}