<template>
  <div
    class="m-16 flex items-center justify-center p-16">
    <ProgressSpinner
      class="loading-spinner"
      :style="`width: ${size}; height: ${size};`"
      strokeWidth="4" />
  </div>
</template>

<script>
import {
  defineComponent
} from "vue";

import ProgressSpinner from "primevue/progressspinner";

export default defineComponent({
  name: "LoadingSpinner",
  components: {
    ProgressSpinner
  },
  props: {
    size: {
      type: String,
      required: false,
      default: "40px"
    }
  }
});
</script>

<style lang="scss"></style>
