import utils from "./utils.js";

import Response from "../models/Response";
import Responses from "../models/Responses";

export default {
  state: {
    responses: null,
    unsavedChanges: []
  },
  getters: {
    getUserResponses: (state) => (user) => {
      if (state.responses && state.responses.length > 0 && user) {
        return state.responses.filter(response => response.user_id == user.id);
      }
      return null;
    },
    getSectionResponses: (state) => (section) => {
      if (state.responses && state.responses.length > 0 && section) {
        return state.responses.filter(response => response.section_id == section.id);
      }
      return null;
    },
    getLessonResponses: (state) => (lesson) => {
      if (state.responses && state.responses.length > 0 && lesson) {
        return state.responses.filter(response => response.lesson_id == lesson.id);
      }
      return null;
    },
    getResponses: (state) => () => {
      return state.responses;
    },
    getResponse: (state) => (section_id) => {
      if (state.responses && state.responses.length > 0) {
        return state.responses.find(response => response.section_id == section_id);
      }
      return null;
    },
    hasUnsavedChanges: (state) => (id, ids = []) => {
      if (id) {
        return state.unsavedChanges.find(i => i === id);
      }
      return state.unsavedChanges.filter(e => ids.includes(e))?.length > 0;
    }
  },
  actions: {
    async loadResponses({ commit }, params) {
      try {
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let toolkit = utils.try(params, "toolkit", null);
        let lesson = utils.try(params, "lesson", null);
        let user = utils.try(params, "user", null);
        let deleted = utils.try(params, "deleted", false);
        let responses = await Responses.load(toolkit, lesson, user, search, offset, limit, deleted);
        commit("setResponses", responses);
        return Promise.resolve(responses);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadResponse({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let toolkit = utils.try(params, "toolkit", null);
        let lesson = utils.try(params, "lesson", null);
        let section = utils.try(params, "section", null);
        let response = refresh ? null : await Response.restore(toolkit, lesson, section, params.id);
        if (response == null) {
          response = await Response.load(toolkit, lesson, section, params.id);
          if (response) {
            await response.store();
          }
        }
        commit("setResponse", response);
        return Promise.resolve(response);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveResponse({
      commit, rootGetters
    }, params) {
      try {
        let _response = new Response();
        _response.user_id = rootGetters.getUserId();
        _response.toolkit_id = params.toolkit_id;
        _response.chapter_id = params.chapter_id;
        _response.lesson_id = params.lesson_id;
        _response.section_id = params.section_id;
        _response.value = params.value;
        _response.type = utils.try(params, "type", "Response");
        _response.secret = utils.try(params, "secret", false);
        _response.image_url = utils.try(params, "image_url", null);
        _response.image_data = utils.try(params, "image_data", null);
        let response = await _response.save();
        await response.store();
        commit("setResponse", response);
        return Promise.resolve(response);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {
    setResponses: (state, responses) => {
      state.responses = responses;
    },
    setResponse: (state, response) => {
      const index = state.responses.findIndex(_response => _response.id === response.id);
      if (index > -1) {
        state.responses.splice(index, 1, response);
      }
      else {
        state.responses.push(response);
      }
    },
    resetResponses: (state) => {
      state.responses = null;
    },
    setUnsavedChanges: (state, id) => {
      if (state.unsavedChanges.includes(id)) {
        state.unsavedChanges = state.unsavedChanges.filter(i => i !== id);
      }
      else {
        state.unsavedChanges.push(id);
      }
    }
  }
};