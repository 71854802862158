import utils from "./utils.js";

import Assistant from "../models/Assistant";
import Assistants from "../models/Assistants";

export default {
  state: {
    assistants: null,
    assistant: null
  },
  getters: {
    getAssistants: (state) => () => {
      return state.assistants;
    },
    getSchoolAssistants: (state) => (school) => {
      if (state.assistants && state.assistants.length > 0 && school) {
        return state.assistants.filter(assistant => assistant.school_id && assistant.school_id == school.id);
      }
      return [];
    },
    getAssistant: (state) => (id) => {
      if (state.assistants && state.assistants.length > 0 && id) {
        return state.assistants.find(assistant => assistant.id == id);
      }
      return state.assistant;
    }
  },
  actions: {
    async loadAssistants({commit}, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 48);
        let school = utils.try(params, "school", null);
        if (refresh || (await Assistants.count(school, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let assistants = await Assistants.load(school, search, offset, limit, deleted);
          if (assistants && assistants.length > 0) {
            await assistants.store();
          }
        }
        let assistants = await Assistants.restore(school, search, 0, offset + limit);
        commit("setAssistants", assistants);
        return Promise.resolve(assistants);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadAssistant({commit}, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let assistant = refresh ? null : await Assistant.restore(params.id);
        if (assistant == null) {
          assistant = await Assistant.load(params.id);
          if (assistant) {
            await assistant.store();
          }
        }
        commit("setAssistant", assistant);
        return Promise.resolve(assistant);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveAssistant({commit}, params = {}) {
      try {
        let _assistant = new Assistant();
        _assistant.id = utils.try(params, "id", null);
        _assistant.name = utils.try(params, "name", null);
        _assistant.email = utils.try(params, "email", null);
        let assistant = await _assistant.save();
        await assistant.store();
        commit("setAssistant", assistant);
        return Promise.resolve(assistant);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async inviteAssistant({commit}, params = {}) {
      try {
        let school_id = utils.try(params, "school_id", null);
        let classroom_id = utils.try(params, "classroom_id", null);
        let _assistant = new Assistant();
        _assistant.name = utils.try(params, "name", null);
        _assistant.email = utils.try(params, "email", null);
        let assistant = await _assistant.invite(school_id, classroom_id);
        await assistant.store();
        commit("setAssistant", assistant);
        return Promise.resolve(assistant);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async inviteAssistants({commit}, params = {}) {
      try {
        let school_id = utils.try(params, "school_id", null);
        let assistants = await Assistants.invite(school_id, params.assistants);
        if (assistants && assistants.length > 0) {
          await assistants.store();
        }
        return Promise.resolve(assistants);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteAssistant({commit}, params = {}) {
      let assistant = new Assistant();
      assistant.id = params.id || params.assistant_id;
      await assistant.delete();
      commit("removeAssistant", assistant);
      return Promise.resolve(null);
    }
  },
  mutations: {
    setAssistants: (state, assistants) => {
      state.assistants = assistants;
    },
    setAssistant: (state, assistant) => {
      state.assistant = assistant;
      if (state.assistants) {
        const index = state.assistants.findIndex(_assistant => _assistant.id === assistant.id);
        if (index > -1) {
          state.assistants.splice(index, 1, assistant);
        }
        else {
          state.assistants.push(assistant);
        }
      }
    },
    removeAssistant(state, assistant) {
      if (state.assistants) {
        const index =
          state.assistants.findIndex(_assistant => _assistant.id === assistant.id);
        if (index > -1) {
          state.assistants.splice(index, 1);
        }
      }
    },
    resetAssistants: (state) => {
      state.assistants = null;
      state.assistant = null;
    }
  }
};