import utils from "./utils.js";

import Assignment from "../models/Assignment";
import Assignments from "../models/Assignments";

export default {
  state: {
    assignments: null,
    assignmentsList: [],
    assignmentsPagination: {
      requestHeaders: {
        page: 1,
        per_page: 5
      },
      responseHeaders: {}
    }
  },
  getters: {
    getAssignments: (state) => () => {
      return state.assignments;
    },
    assignmentsList(state) {
      return state.assignmentsList;
    },
    assignmentsPagination(state) {
      return state.assignmentsPagination;
    },
    getLessonAssignments: (state) => (lesson) => {
      if (state.assignments && lesson) {
        return state.assignments.filter(assignment => assignment.lesson_id == lesson.id && assignment.due_date && assignment.due_date.length > 0);
      }
      return [];
    },
    getClassroomAssignments: (state) => (classroom) => {
      if (state.assignments && classroom) {
        return state.assignments.filter(assignment => assignment.classroom_id == classroom.id && assignment.due_date && assignment.due_date.length > 0);
      }
      return [];
    },
    getLessonClassroomAssignment: (state) => (lesson, classroom) => {
      if (state.assignments && lesson && classroom) {
        return state.assignments.find(assignment => assignment.lesson_id === lesson.id && assignment.classroom_id === classroom.id && assignment.due_date && assignment.due_date.length > 0);
      }
      return null;
    }
  },
  actions: {
    async loadAssignments({ commit }, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let lesson = utils.try(params, "lesson", null);
        let classroom_id = utils.try(params, "classroom_id", null);
        let student = utils.try(params, "student", null);
        if (refresh || (await Assignments.count(student, lesson, classroom_id, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let assignments = await Assignments.load(student, lesson, classroom_id, search, offset, limit, deleted);
          if (assignments && assignments.length > 0) {
            await assignments.store();
          }
          commit("setAssignments", assignments);
          return Promise.resolve(assignments);
        }
        else {
          let assignments = await Assignments.restore(lesson, classroom_id, search, 0, offset + limit);
          commit("setAssignments", assignments);
          return Promise.resolve(assignments);
        }
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveAssignment({
      commit
    }, params = {}) {
      try {
        let _assignment = new Assignment();
        _assignment.id = utils.try(params, "id", null);
        _assignment.lesson_id = utils.try(params, "lesson_id", null);
        _assignment.classroom_id = utils.try(params, "classroom_id", null);
        _assignment.due_date = utils.try(params, "due_date", "");
        _assignment.pending_student_ids = utils.try(params, "pending_student_ids", []);
        _assignment.submitted_student_ids = utils.try(params, "submitted_student_ids", []);
        _assignment.reviewed_student_ids = utils.try(params, "reviewed_student_ids", []);
        let assignment = await _assignment.save();
        await assignment.store();
        commit("setAssignment", assignment);
        return Promise.resolve(assignment);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteAssignment({commit}, params = {}) {
      let assignment = new Assignment();
      assignment.id = params.id;
      assignment.classroom_id = params.classroom_id;
      assignment.lesson_id = params.lesson_id;
      await assignment.delete();
      commit("removeAssignment", assignment);
      return Promise.resolve(null);
    },
    async markComplete(_args, params = {}) {
      const url = process.env.VUE_APP_API_URL + "/collections/restore-share.json";
      const response = await Server.post(url, params);
      return response;
    },
    async loadAssignmentsPaginated({ commit }, params) {
      let _assignments = new Assignments();
      const response = await _assignments.loadAssignments(params);
      commit("setAssignmentsList", response.data);
      commit("setAssignmentsReponseHeaders", response.pagination);
    }
  },
  mutations: {
    setAssignments: (state, assignments) => {
      state.assignments = assignments;
    },
    setAssignment: (state, assignment) => {
      if (state.assignments && state.assignments.length > 0) {
        const index = state.assignments.findIndex(_assignment => _assignment.id === assignment.id);
        if (index > -1) {
          state.assignments.splice(index, 1, assignment);
        }
        else {
          state.assignments.push(assignment);
        }
      }
    },
    setAssignmentsList: (state, assignments) => {
      state.assignmentsList = assignments;
    },
    setAssignmentsReponseHeaders: (state, headers) => {
      state.assignmentsPagination.responseHeaders = headers;
    },
    setAssignmentsRequestHeaders: (state, headers) => {
      state.assignmentsPagination.requestHeaders = headers;
    },
    removeAssignment(state, assignment) {
      if (state.assignments) {
        const index = state.assignments.findIndex(_assignment => _assignment.id === assignment.id);
        if (index > -1) {
          state.assignments.splice(index, 1);
        }
      }
    },
    resetAssignments: (state) => {
      state.assignments = null;
    }
  }
};
