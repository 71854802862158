const initialState = () => ({
  appLoading: false,
  drawingEnabled: false
});

export default {
  state: initialState,
  actions: {
    setAppLoading({ commit }, value) {
      commit("setAppLoading", value);
    }
  },
  mutations: {
    setAppLoading(state, value) {
      state.appLoading = value;
    },
    setDrawing(state, value) {
      state.drawingEnabled = value;
    }
  },
  getters: {
    appLoading(state) {
      return state.appLoading;
    },
    drawingEnabled(state) {
      return state.drawingEnabled;
    }
  }
};
