import Password from "../models/Password";

export default {
  state: {},
  getters: {},
  actions: {
    async forgotPassword({ commit }, params) {
      try {
        await Password.forgot(params.email);
        return Promise.resolve(true);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async resetPassword({ commit }, params) {
      try {
        commit("resetUsers");
        let user = await Password.reset(params.token, params.password);
        await user.store();
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {}
};
