import * as localForage from "localforage";

export default class Storage {

  static initialize() {
    localForage.config({
      driver: [
        localForage.INDEXEDDB,
        localForage.WEBSQL,
        localForage.LOCALSTORAGE
      ],
      name: "Project-Wayfinder"
    });
  }

  static async keys(prefix = null) {
    if (prefix && prefix.length > 0) {
      let keys = await localForage.keys();
      return keys.filter(key => key.startsWith(prefix));
    }
    return await localForage.keys();
  }

  static async get(key) {
    return await localForage.getItem(key);
  }

  static async set(key, value) {
    if (value) {
      return await localForage.setItem(key, value);
    }
    return await localForage.setItem(key, null);
  }

  static async count(prefix, needle = "", haystack = null, filters = {}) {
    let counts = 0;
    let keys = await Storage.keys(prefix);
    let search = needle && needle.length > 0 ? needle.toLowerCase() : "";
    for (let key of keys) {
      const item = await Storage.get(key);
      if (item) {
        if (search && search.length > 0) {
          if (haystack && haystack.length > 0) {
            for (let attribute of haystack.split(",")) {
              let value = item[attribute];
              if (value && Array.isArray(value) && value.includes(search)) {
                counts = counts + 1;
                break;
              }
              else if (value && value.toString().toLowerCase().indexOf(search) != -1) {
                counts = counts + 1;
                break;
              }
            }
          }
          else {
            for (let value of Object.values(item)) {
              if (value && value.toString().toLowerCase().indexOf(search) != -1) {
                counts = counts + 1;
                break;
              }
            }
          }
        }
        else {
          counts = counts + 1;
        }
      }
    }
    return counts;
  }

  static async search(prefix, needle = "", haystack = null, offset = 0, limit = 100, sort = null) {
    let results = [];
    let keys = await Storage.keys(prefix);
    let search = needle && needle.length > 0 ? needle.toLowerCase() : "";
    for (let key of keys) {
      const item = await Storage.get(key);
      if (item) {
        if (search && search.length > 0) {
          if (haystack && haystack.length > 0) {
            for (let attribute of haystack.split(",")) {
              let value = item[attribute];
              if (value && Array.isArray(value) && value.includes(search)) {
                results.push(item);
                break;
              }
              else if (value && value.toString().toLowerCase().indexOf(search) != -1) {
                results.push(item);
                break;
              }
            }
          }
          else {
            for (let value of Object.values(item)) {
              if (value && value.toString().toLowerCase().indexOf(search) != -1) {
                results.push(item);
                break;
              }
            }
          }
        }
        else {
          results.push(item);
        }
      }
    }
    if (sort && sort.length > 0) {
      return results.sort(Storage.sortByProperties(sort.split(","))).slice(offset, offset + limit);
    }
    return results.slice(offset, offset + limit);
  }

  static async remove(key) {
    return await localForage.removeItem(key);
  }

  static async clear(prefix = null) {
    if (prefix && prefix.length > 0) {
      let keys = await Storage.keys(prefix);
      for (let key of keys) {
        await localForage.removeItem(key);
      }
      return true;
    }
    return await localForage.clear();
  }

  static async clearData() {
    const keys = await localForage.keys();
    for await (let key of keys) {
      if (key !== "user") {
        await localForage.removeItem(key);
      }
    }
  }

  static sortByProperties(properties) {
    return (a, b) => {
      let i = 0;
      let result = 0;
      let numberOfProperties = properties.length;
      while (result === 0 && i < numberOfProperties) {
        result = Storage.sortByProperty(properties[i])(a, b);
        i++;
      }
      return result;
    };
  }

  static sortByProperty(property) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1, property.length - 1);
    }
    return (a, b) => {
      if (typeof a[property] == "boolean") {
        return ((a[property] === b[property]) ? 0 : a[property] ? -1 : 1) * sortOrder;
      }
      if (typeof a[property] == "number") {
        return (a[property] - b[property]) * sortOrder;
      }

      const aValue = a[property]?.toLowerCase === "function" ? a[property]?.toLowerCase() : a[property];
      const bValue = b[property]?.toLowerCase === "function" ? b[property]?.toLowerCase() : b[property];
      return ((aValue < bValue) ? -1 : (aValue > bValue)) * sortOrder;
    };
  }

}