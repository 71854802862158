import Model from "./Model";

export default class Note extends Model {
  id = null;
  title = null;
  description = null;
  selected_options = [];
  student_id = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`notes/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Note, `/${id}`);
  }

  async save() {
    let path = this.id && this.id.length > 0 ? `notes/${this.id}.json` : "notes.json";
    let params = {
      title: this.title,
      description: this.description,
      student_id: this.student_id
    };
    return this._save(Note, path, params);
  }

  async saveBulk() {
    return this._save(Note, "notes/bulk.json", {
      title: this.title,
      description: this.description,
      selected_options: this.selected_options
    });
  }

  async delete() {
    await this._delete(`notes/${this.id}.json`);
    return null;
  }

}