import Models from "./Models";
import Note from "./Note";

export default class Notes extends Models {

  constructor(models = []) {
    super(Note, models);
  }

  static async load(student_id, search = "", offset = 0, limit = 100, deleted = false) {
    let path = `notes.json?student_id=${student_id}`;
    return Models._load(Notes, Note, path, search, offset, limit, deleted);
  }

}