import Model from "./Model";
import Teachers from "./Teachers";
import Students from "./Students";
import Classrooms from "./Classrooms";

export default class School extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  country = null;
  state = null;
  city = null;
  street = null;
  zipcode = null;
  image = null;
  principal_id = null;
  created_at = null;
  updated_at = null;
  teachers = new Teachers();
  students = new Students();
  classrooms = new Classrooms();
  toolkit_ids = [];
  classroom_ids = [];
  teacher_ids = [];
  student_ids = [];

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["teachers"]) {
      this.teachers = new Teachers(data["teachers"]);
    }
    else {
      this.teachers = new Teachers();
    }
    if (data && data["students"]) {
      this.students = new Students(data["students"]);
    }
    else {
      this.students = new Students();
    }
    if (data && data["classrooms"]) {
      this.classrooms = new Classrooms(data["classrooms"]);
    }
    else {
      this.classrooms = new Classrooms();
    }
  }

  async store() {
    return this._store(`schools/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(School, `schools/${id}`);
  }

  static async load(id, teachers = false, students = false, classrooms = false, licenses = false) {
    return Model._load(School, `schools/${id}.json?teachers=${teachers}&students=${students}&classrooms=${classrooms}&licenses=${licenses}`);
  }

  async save(image_data = null, remove_image = null) {
    let path = this.id && this.id.length > 0 ? `schools/${this.id}.json` : "schools.json";
    return this._save(School, path, {
      name: this.name,
      title: this.title,
      description: this.description,
      country: this.country,
      state: this.state,
      city: this.city,
      street: this.street,
      zipcode: this.zipcode,
      image: image_data,
      remove_image: remove_image
    });
  }
}