import Model from "./Model";

export default class Assistant extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  image = null;
  initials = null;
  toolkits_count = null;
  classrooms_count = null;
  students_count = null;
  created_at = null;
  updated_at = null;
  invited_at = null;
  accepted_at = null;
  sign_in_at = null;
  toolkits_ids = [];
  school_ids = [];
  classroom_ids = [];
  teacher_ids = [];
  student_ids = [];

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["invited_at"]) {
      this.invited_at = data["invited_at"];
    }
    if (data && data["accepted_at"]) {
      this.accepted_at = data["accepted_at"];
    }
  }

  async store() {
    return this._store(`assistants/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Assistant, `assistants/${id}`);
  }

  static async load(id) {
    return await Model._load(Assistant, `users/${id}.json`);
  }

  async save() {
    return this._save(Assistant, `assistants/${this.id}.json`, {
      name: this.name,
      email: this.email
    });
  }

  async invite(school_id, toolkit_ids = []) {
    let path = `schools/${school_id}/assistants/invite.json`;
    return this._save(Assistant, path, {
      toolkit_ids: toolkit_ids,
      name: this.name,
      email: this.email
    });
  }

  async delete() {
    await this._delete(`assistants/${this.id}.json`);
    await this._remove(`assistants/${this.id}`);
    return null;
  }
}
