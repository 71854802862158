<template>
  <Dialog
    v-if="show"
    v-model:visible="dialog"
    class="splash-modal"
    color="bg-eggshell"
    padding="p-[26px]"
    :disableOutsideDismiss="true"
    :showHeader="false"
    :draggable="false"
    :modal="true"
    :pt="{
      root: 'bg-white rounded-16 px-40 py-30',
    }"
    @keydown.esc="close">
    <div class="modal-container">
      <template
        v-for="(item, i) in config"
        :key="i">
        <div
          v-show="index === i"
          class="z-10 flex flex-col gap-40 lg:flex-row">
          <div class="splash-content">
            <div>
              <div class="mb-10 text-end lg:mb-40 lg:text-start">
                <fa-icon
                  icon="close"
                  color="grey-1"
                  size="lg"
                  :button="true"
                  @click="close">
                </fa-icon>
              </div>
              <div class="h-[32px]">
                <fa-icon
                  v-if="item.icon"
                  :icon="item.icon"
                  color="dark-green"
                  size="2xl"
                  type="regular">
                </fa-icon>
              </div>
              <p class="splash-title">
                {{ item.title }}
              </p>
              <div class="splash-subtitle">
                {{ item.subtitle }}
              </div>
              <Button
                v-if="item.linkButton"
                class="p-button-outlined p-button-rounded p-button-md mt-30 w-[200px] justify-center"
                @click="goTo(item.linkButton.link)">
                {{ item.linkButton.text }}
                <fa-icon
                  v-if="item.linkButton.icon"
                  :icon="item.linkButton.icon"
                  color="dark-green"
                  size="md"
                  type="regular"
                  class="ml-8">
                </fa-icon>
              </Button>
            </div>
            <div class="splash-buttons">
              <form-item
                v-if="toggleable"
                v-model:value="dontShowAgain"
                fieldId="dont-show-again"
                type="checkbox"
                :label="$t('splash_wrapper.do_not_show_again')"
                :hideLabel="true"
                class="text-[11px]">
              </form-item>
              <Button
                v-if="index > 0"
                :label="$t('splash_wrapper.back')"
                class="p-button-text p-button-md"
                @click="index = index - 1">
              </Button>
              <Button
                v-if="index < config.length - 1"
                class="p-button-rounded p-button-md w-[160px] justify-center bg-dark-green"
                @click="index = index + 1">
                {{ $t('splash_wrapper.next') }}
                <fa-icon
                  icon="chevron-right"
                  color="white"
                  size="md"
                  type="regular"
                  class="ml-8">
                </fa-icon>
              </Button>
              <WButton
                v-else
                :label="$t('splash_wrapper.close')"
                @click="close" />
            </div>
          </div>
          <div class="size-full flex-1 self-center">
            <img
              v-if="item.imageUrl"
              alt=""
              :src="getLink(item.imageUrl)"
              class="splash-image" />
            <!-- eslint-disable-next-line vuejs-accessibility/media-has-caption -->
            <iframe
              v-else-if="item.videoUrls?.external"
              class="size-full rounded-10"
              title="Video Player"
              scrolling="yes"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
              mozallowfullscreen="true"
              webkitallowfullscreen="true"
              allowfullscreen="true"
              :src="item.videoUrls.url" />
            <video
              v-else-if="item.videoUrls"
              muted
              autoplay
              loop
              class="splash-video">
              <source
                :src="getLink(item.videoUrls.webm, item.videoUrls.external)"
                type="video/webm" />
              <source
                :src="getLink(item.videoUrls.mp4, item.videoUrls.external)"
                type="video/mp4" />
              Your browser doesn't support HTML5 video.
            </video>
          </div>
        </div>
      </template>
    </div>
    <div class="topo-background"></div>
  </Dialog>
</template>

<script>
import {
  defineComponent
} from "vue";
import {
  mapMutations
} from "vuex";

import Button from "primevue/button";
import Dialog from "primevue/dialog";

import Helpers from "@/mixins/Helpers";
import FaIcon from "@/components/utils/FaIcon";
import FormItem from "@/components/utils/form/FormItem";
import WButton from "@/components/wrappers/WButton";

export default defineComponent({
  components: {
    Button,
    Dialog,
    FaIcon,
    FormItem,
    WButton
  },
  mixins: [
    Helpers
  ],
  props: {
    getterKey: {
      type: String,
      required: true
    },
    config: {
      type: Array,
      required: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      index: 0,
      dontShowAgain: false
    };
  },
  computed: {
    showNotification() {
      return this.$store.getters.dialogs(this.getterKey);
    },
    show() {
      return this.user && this.showNotification;
    },
    isIosSafari() {
      const ua = window.navigator.userAgent;
      const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
      const webkit = !!ua.match(/WebKit/i);
      return iOS && webkit && !ua.match(/CriOS/i);
    }
  },
  watch: {
    showNotification() {
      this.dialog = this.showNotification;
    }
  },
  beforeMount() {
    if (this.showNotification) {
      this.config.forEach((c, i) => {
        if (c.videoUrls) {
          let video = document.createElement("video");
          video.src = this.getLink(this.isIosSafari ? c.videoUrls.mp4 : c.videoUrls.webm, c.videoUrls.external);
          video.autoplay = true;
        }
        else {
          let img = new Image();
          img.src = this.getLink(c.imageUrl);
        }
      });
    }
    this.dialog = this.showNotification;
  },
  methods: {
    ...mapMutations([
      "setDialog"
    ]),
    getLink(url, external) {
      return external ? url : `https://${process.env.VUE_APP_AWS_BUCKET_NAME}.s3.amazonaws.com/${url}`;
    },
    goTo(link) {
      window.open(link, "_blank");
    },
    close() {
      this.toggleable && !this.dontShowAgain ? this.dialog = false : this.setDialog({
        key: this.getterKey,
        value: false
      });
    }
  }
});
</script>

<style lang="scss">
.splash-modal {
  @apply max-w-[1100px];

  .p-dialog-content {
    @apply h-full;
  }
}

.splash-modal .p-dialog-content {
  @apply p-30 bg-[#f6f4f3];
}

.modal-container {
  @apply h-full flex z-10;
}

.splash-content {
  @apply w-full lg:w-1/3 flex flex-col justify-between gap-30 z-10 mx-0;

  .splash-title {
    @apply text-[30px] leading-[40px] font-bold text-dark-green mt-16 mb-[26px] font-lora;
  }

  .splash-subtitle {
    @apply text-14 text-dark-green;
  }

  .splash-buttons {
    @apply flex justify-center lg:justify-between items-center gap-30 mt-30;
  }
}

.splash-image {
  @apply rounded-16 shadow-card w-full ml-auto z-10 min-w-[400px] max-w-[600px];
}
.splash-video {
  @apply rounded-16 shadow-card w-full ml-auto z-10 aspect-video;
}

.topo-background {
  @apply absolute top-0 left-0 h-full w-full opacity-70 rounded-16 bg-dark-green;
  background: url('@/assets/topo-texture-inf.png') repeat;
  z-index: 0;
}
</style>
