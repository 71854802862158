import utils from "./utils.js";

import Note from "../models/Note";
import Notes from "../models/Notes";

export default {
  state: {
    notes: null,
    note: null
  },
  getters: {
    notes(state) {
      return state.notes;
    }
  },
  actions: {
    async saveNote({ commit }, params = {}, store = true) {
      try {
        let _note = new Note();
        _note.id = utils.try(params, "id", null);
        _note.student_id = utils.try(params, "student_id", "");
        _note.title = utils.try(params, "title", "");
        _note.description = utils.try(params, "description", "");
        let note = await _note.save();
        await note.store();
        commit("setNote", note);
        return Promise.resolve();
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveBulkNotes({ commit }, params = {}) {
      try {
        let _note = new Note();
        _note.selected_options = utils.try(params, "selected_options", "");
        _note.title = utils.try(params, "title", "");
        _note.description = utils.try(params, "description", "");
        let note = await _note.saveBulk();
        // await note.store(); nothing to store as a blank 204 response is returned for bulk save
        return Promise.resolve(); // note not in parens for same reason
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteNote({commit}, params = {}) {
      let note = new Note();
      note.id = params.id;
      await note.delete();
      commit("removeNote", note);
      return Promise.resolve(null);
    },
    async loadNotes({commit}, params = {}) {
      try {
        let student_id = utils.try(params, "student_id", false);
        let notes = await Notes.load(student_id);
        commit("setNotes", notes);
        return Promise.resolve(notes);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {
    setNotes: (state, notes) => {
      state.notes = notes;
    },
    setNote: (state, note) => {
      if (state.notes) { // might not exist if not loaded on page
        const index = state.notes.findIndex(_note => _note.id === note.id);
        if (index > -1) {
          state.notes.splice(index, 1, note);
        }
        else {
          state.notes.push(note);
        }
      }
    },
    removeNote(state, note) {
      if (state.notes) {
        const index = state.notes.findIndex(_note => _note.id === note.id);
        if (index > -1) {
          state.notes.splice(index, 1);
        }
      }
    }
  }
};