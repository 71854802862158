import dayjs from "dayjs";
import Models from "./Models";
import Waypoint from "./Waypoint";

export default class Waypoints extends Models {

  constructor(models = []) {
    super(Waypoint, models);
  }

  static async clear() {
    return Models._clear("waypoints");
  }

  static async restore(search = "", offset = 0, limit = 10) {
    let today = dayjs(new Date()).format("YYYY-MM-DD");
    let waypoints = await Models._restore(Waypoints, Waypoint, "waypoints/", search, 0, 100, "-started_on", "name,title,description");
    waypoints = waypoints.filter(waypoint => waypoint.started_on == null || waypoint.started_on == "" || waypoint.started_on <= today);
    return waypoints.slice(offset, offset + limit);
  }

  static async load(filters, search = "", offset = 0, limit = 10, deleted = false) {
    let path = "waypoints.json";
    if (filters) {
      path = path + `?${this.encodeParams(filters)}`;
    }
    return Models._load(Waypoints, Waypoint, path, search, offset, limit, deleted);
  }
}
