import Model from "./Model";
import Questions from "./Questions";
import Server from "@/services/Server";

export default class Waypoint extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  active = true;
  description = null;
  questions_count = 0;
  created_at = null;
  updated_at = null;
  started_on = null;
  finished_on = null;
  waypoint_completions = [];
  questions = new Questions();

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["questions"]) {
      this.questions = new Questions(data["questions"]);
    }
    else {
      this.questions = new Questions();
    }
  }

  async store() {
    this._store(`waypoints/${this.id}`);
    if (this.questions && this.questions.length > 0) {
      this.questions.store();
    }
    return this;
  }

  static async restore(id, questions = false) {
    let waypoint = Model._restore(Waypoint, `waypoints/${id}`);
    if (waypoint && questions) {
      waypoint.questions = Questions.restore(null, id);
    }
    return waypoint;
  }

  static async load(id, questions = false) {
    return Model._load(Waypoint, `waypoints/${id}.json?questions=${questions}`);
  }

  static async complete(id) {
    const path = `waypoints/${id}/submit.json`;
    const response = await Server.post(path);
    return response;
  }

  static async submitNote(params) {
    const path = `waypoints/${params.waypoint_id}/submit_completion_note.json`;
    const response = await Server.post(path, params);
    return response;
  }

  async export() {
    let url = process.env.VUE_APP_API_URL + `/waypoints/${this.id}/export.json`;
    let response = await Server.post(url);
    return response != null;
  }

}