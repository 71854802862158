import Lti from "@/models/Lti";

let _lti = new Lti();

export default {
  state: {
    tools: []
  },
  getters: {
    tools(state) {
      return state.tools;
    }
  },
  actions: {
    async createLitTool({
      commit, rootState
    }, params) {
      const district = rootState.districts.district.id;
      const response = await _lti.createLtiTool(district, params);
      commit("setTool", response);
      return response;
    },
    async updateLitTool({
      commit, rootState
    }, {
      params, tool
    }) {
      const district = rootState.districts.district.id;
      const response = await _lti.updateLtiTool(district, tool, params);
      return commit("setTool", response);
    },
    async getLtiTools({
      commit, rootState
    }) {
      const district = rootState.districts.district.id;
      const response = await _lti.getLtiTools(district);
      commit("setTools", response);
    },
    async createLtiAssignment({ commit }, payload) {
      const params = payload.lesson_ids ? {
        lesson_ids: payload.lesson_ids
      } : {
        waypoint_ids: payload.waypoint_ids
      };
      await _lti.createLtiAssignment(payload.district, payload.tool, payload.launch, params);
    }
  },
  mutations: {
    setTools: (state, tools) => {
      state.tools = tools;
    },
    setTool: (state, tool) => {
      const item = state.tools.find(item => item.id === tool.id);
      if (item) {
        Object.assign(item, tool);
      }
      else {
        state.tools.push(tool);
      }
    }
  }
};