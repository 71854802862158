import Model from "./Model";

export default class Answer extends Model {

  id = null;
  type = null;
  icon = null;
  value = null;
  values = null;
  user_id = null;
  question_id = null;
  answerable_id = null;
  answerable_type = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`answers/${this.answerable_id}/${this.question_id}/${this.user_id}`);
  }

  static async restore(answerable_id, question_id, user_id) {
    return Model._restore(Answer, `answers/${answerable_id}/${question_id}/${user_id}`);
  }

  async save() {
    return this._save(Answer, "answers.json", {
      ...(this.user_id ? { user_id: this.user_id } : {}),
      question_id: this.question_id,
      answerable_id: this.answerable_id,
      answerable_type: this.answerable_type,
      value: this.value
    });
  }
}