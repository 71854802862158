import utils from "./utils.js";
import District from "@/models/District";

export default {
  state: {
    district: null,
    // import logs
    importLogs: [],
    importLogsPagination: {
      requestHeaders: {
        page: 1,
        per_page: 10
      },
      responseHeaders: {}
    },
    importLogDetails: {},
    // users
    users: [],
    userPagination: {
      requestHeaders: {
        page: 1,
        per_page: 10
      },
      responseHeaders: {}
    }
  },
  getters: {
    district(state) {
      return state.district;
    },
    // import logs
    importLogs(state) {
      return state.importLogs;
    },
    importLogsPagination(state) {
      return state.importLogsPagination.responseHeaders;
    },
    importLogsRequestHeaders(state) {
      return state.importLogsPagination.requestHeaders;
    },
    importLogDetails(state) {
      return state.importLogDetails;
    },
    // users
    users(state) {
      return state.users;
    },
    userPagination(state) {
      return state.userPagination.responseHeaders;
    },
    userRequestHeaders(state) {
      return state.userPagination.requestHeaders;
    }
  },
  actions: {
    // district/import actions
    async loadDistrict({
      commit, rootGetters
    }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let _district = new District();
        _district.id = rootGetters.getUser().district_id;
        let district = refresh ? await _district.load() : await _district.restore();
        if (district) {
          await district.store();
        }
        commit("setDistrict", district);
        return Promise.resolve(district);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveRosteringSettings({
      commit, state
    }, form) {
      let _district = new District();
      _district.id = state.district.id;
      const response = await _district.updateRosteringInfo({
        district: {
          rostering: true,
          ...form
        }
      });
      commit("setDistrict", response);
    },
    async manualUpload({ state }, zipFile) {
      let _district = new District();
      _district.id = state.district.id;
      const response = await _district.manualUpload({
        import: {
          zip_file_remote_url: zipFile
        }
      });
      return response;
    },
    async runSftpImport({ state }) {
      let _district = new District();
      _district.id = state.district.id;
      const response = await _district.runSftpImport();
      return response;
    },
    // import logs
    async loadImportLogs({
      commit, state
    }, params) {
      let _district = new District();
      _district.id = state.district.id;
      const response = await _district.loadImportLogs({
        ...params,
        ...state.importLogsPagination.requestHeaders
      });
      commit("setImportLogs", response);
      commit("setImportLogsPagination", response.pagination);
    },
    async loadImportLogDetails({
      commit, state
    }, logId) {
      let _district = new District();
      _district.id = state.district.id;
      const response = await _district.loadImportLogDetails(logId);
      commit("setImportLogDetails", response);
    },
    async isImportRunning({
      commit, state
    }, logId) {
      let _district = new District();
      _district.id = state.district.id;
      const response = await _district.isImportRunning(logId);
      return response?.running;
    },
    // users
    async loadUsers({
      commit, state
    }, params) {
      let _district = new District();
      const response = await _district.loadUsers({
        ...state.userPagination.requestHeaders,
        ...params
      });
      commit("setUsers", response);
      commit("setUserPagination", response.pagination);
    },
    async loadUserData({ commit }, params) {
      let _district = new District();
      const response = await _district.loadUserData(params);
      return response;
    },
    async inviteUser({
      commit, state
    }, params) {
      let _district = new District();
      const response = await _district.inviteUser({
        ...params,
        district_id: state.district.id
      });
      commit("addUser", response);
    },
    async resendInvite({ state }, params) {
      let _district = new District();
      await _district.resendInvite(params);
    },
    async updateUser({
      commit, state
    }, params) {
      let _district = new District();
      // passing in single class for now
      delete params.classroom_id;
      // not changing these values
      delete params.type;
      // needs to pass params wrapped in user obj
      let user = {
        user: {
          district_id: state.district?.id,
          ...params
        }
      };
      const response = await _district.updateUser(user);
      commit("updateUser", response);
    },
    async archiveUser({
      commit, state
    }, params) {
      let user = state.users.find(u => u.id === params.user);
      let _district = new District();
      await _district.archiveUser({
        ...params
      });
      commit("removeUser", user);
    },
    async restoreUser({ commit }, params) {
      let _district = new District();
      await _district.restoreUser({
        restore_ids: params.id
      });
      commit("removeUser", {
        id: params.id
      });
    },
    async bulkInvite({ commit }, params) {
      let _district = new District();
      await _district.bulkInvite(params);
    },
    async updateDistrictInfo({rootState, dispatch}, params) {
      let _district = new District();
      _district.id = rootState.users.user.district_id;
      await _district.updateDistrictInfo({district: params});
      await dispatch("userProfile");
    }
  },
  mutations: {
    setDistrict: (state, district) => {
      state.district = district;
    },
    resetDistricts: (state) => {
      state.district = null;
      state.importLogs = [];
      state.importLogDetails = {};
      state.users = [];
    },
    // import logs
    setImportLogs: (state, response) => {
      state.importLogs = response.data;
    },
    setImportLogDetails: (state, details) => {
      state.importLogDetails = details;
    },
    setImportLogsPagination: (state, responseHeaders) => {
      state.importLogsPagination.responseHeaders = responseHeaders;
    },
    setImportLogsRequestHeaders: (state, requestHeaders) => {
      state.importLogsPagination.requestHeaders = requestHeaders;
    },
    // users
    setUsers: (state, response) => {
      state.users = response.data;
    },
    addUser: (state, user) => {
      state.users.push(user);
    },
    updateUser: (state, user) => {
      const index = state.users.findIndex(_user => _user.id === user.id);
      state.users[index] = user;
    },
    removeUser(state, user) {
      if (state.users) {
        const index = state.users.findIndex(_user => _user.id === user.id);
        if (index > -1) {
          state.users.splice(index, 1);
        }
      }
    },
    setUserPagination: (state, responseHeaders) => {
      state.userPagination.responseHeaders = responseHeaders;
    },
    setUserRequestHeaders: (state, requestHeaders) => {
      state.userPagination.requestHeaders = requestHeaders;
    }
  }
};
