import moment from "moment";

let pluralize = require("pluralize");
let WHITE_SPACES = [
  " ",
  "\n",
  "\r",
  "\t",
  "\f",
  "\v",
  "\u00A0",
  "\u1680",
  "\u180E",
  "\u2000",
  "\u2001",
  "\u2002",
  "\u2003",
  "\u2004",
  "\u2005",
  "\u2006",
  "\u2007",
  "\u2008",
  "\u2009",
  "\u200A",
  "\u2028",
  "\u2029",
  "\u202F",
  "\u205F",
  "\u3000"
];
export default {
  methods: {
    random(items) {
      return items[items.length * Math.random() | 0];
    },
    upperCase(str) {
      return str.toUpperCase();
    },
    lowerCase(str) {
      return str.toLowerCase();
    },
    pluralize(count, word, inclusive = true) {
      return pluralize(this.$t(word), count, inclusive);
    },
    camelCase(str) {
      str = replaceAccents(str);
      str = removeNonWord(str)
        .replace(/\-/g, " ")
        .replace(/\s[a-z]/g, upperCase)
        .replace(/\s+/g, "")
        .replace(/^[A-Z]/g, lowerCase);
      return str;
    },
    unCamelCase(str) {
      str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
      str = str.toLowerCase();
      return str;
    },
    properCase(str) {
      return lowerCase(str).replace(/^\w|\s\w/g, upperCase);
    },
    pascalCase(str) {
      return camelCase(str).replace(/^[a-z]/, upperCase);
    },
    sentenceCase(str) {
      return lowerCase(str).replace(/(^\w)|\.\s+(\w)/gm, upperCase);
    },
    hyphenate(str) {
      str = unCamelCase(str);
      return slugify(str, "-");
    },
    unhyphenate(str) {
      return str.replace(/(\w)(-)(\w)/g, "$1 $3");
    },
    underscore(str) {
      str = unCamelCase(str);
      return slugify(str, "_");
    },
    contains(str, substring, fromIndex) {
      return str.indexOf(substring, fromIndex) !== -1;
    },
    crop(str, maxChars, append) {
      return truncate(str, maxChars, append, true);
    },
    trim(str, chars) {
      chars = chars || WHITE_SPACES;
      return ltrim(rtrim(str, chars), chars);
    },
    rtrim(str, chars) {
      chars = chars || WHITE_SPACES;
      let end = str.length - 1;
      let charLen = chars.length;
      let found = true;
      let i;
      let c;
      while (found && end >= 0) {
        found = false;
        i = -1;
        c = str.charAt(end);
        while (++i < charLen) {
          if (c === chars[i]) {
            found = true;
            end--;
            break;
          }
        }
      }
      return end >= 0 ? str.substring(0, end + 1) : "";
    },
    ltrim(str, chars) {
      chars = chars || WHITE_SPACES;
      let start = 0;
      let len = str.length;
      let charLen = chars.length;
      let found = true;
      let i;
      let c;
      while (found && start < len) {
        found = false;
        i = -1;
        c = str.charAt(start);
        while (++i < charLen) {
          if (c === chars[i]) {
            found = true;
            start++;
            break;
          }
        }
      }
      return start >= len ? "" : str.substr(start, len);
    },
    abbreviate(str) {
      return str.match(/\b([A-Z])/g).join("");
    },
    truncate(str, maxChars, append, onlyFullWords) {
      append = append || "...";
      maxChars = onlyFullWords ? maxChars + 1 : maxChars;
      str = trim(str);
      if (str.length <= maxChars) {
        return str;
      }
      str = str.substr(0, maxChars - append.length);
      str = onlyFullWords ? str.substr(0, str.lastIndexOf(" ")) : trim(str);
      return str + append;
    },
    repeat(str, n) {
      return new Array(n + 1).join(str);
    },
    lpad(str, minLen, ch) {
      ch = ch || " ";
      return str.length < minLen ? repeat(ch, minLen - str.length) + str : str;
    },
    rpad(str, minLen, ch) {
      ch = ch || " ";
      return str.length < minLen ? str + repeat(ch, minLen - str.length) : str;
    },
    stripHtmlTags(str) {
      return str.replace(/<[^>]*>/g, "");
    },
    unescapeHtml(str) {
      str = str
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&#39;/g, "'")
        .replace(/&quot;/g, "\"");
      return str;
    },
    escapeHtml(str) {
      str = str
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/'/g, "&#39;")
        .replace(/"/g, "&quot;");
      return str;
    },
    escapeRegExp(str) {
      let ESCAPE_CHARS = /[\\.+*?\^$\[\](){}\/'#]/g;
      return str.replace(ESCAPE_CHARS, "\\$&");
    },
    replaceAccents(str) {
      if (str.search(/[\xC0-\xFF]/g) > -1) {
        str = str
          .replace(/[\xC0-\xC5]/g, "A")
          .replace(/[\xC6]/g, "AE")
          .replace(/[\xC7]/g, "C")
          .replace(/[\xC8-\xCB]/g, "E")
          .replace(/[\xCC-\xCF]/g, "I")
          .replace(/[\xD0]/g, "D")
          .replace(/[\xD1]/g, "N")
          .replace(/[\xD2-\xD6\xD8]/g, "O")
          .replace(/[\xD9-\xDC]/g, "U")
          .replace(/[\xDD]/g, "Y")
          .replace(/[\xDE]/g, "P")
          .replace(/[\xE0-\xE5]/g, "a")
          .replace(/[\xE6]/g, "ae")
          .replace(/[\xE7]/g, "c")
          .replace(/[\xE8-\xEB]/g, "e")
          .replace(/[\xEC-\xEF]/g, "i")
          .replace(/[\xF1]/g, "n")
          .replace(/[\xF2-\xF6\xF8]/g, "o")
          .replace(/[\xF9-\xFC]/g, "u")
          .replace(/[\xFE]/g, "p")
          .replace(/[\xFD\xFF]/g, "y");
      }
      return str;
    },
    normalizeLineBreaks(str, lineEnd) {
      lineEnd = lineEnd || "\n";
      return str
        .replace(/\r\n/g, lineEnd) // DOS
        .replace(/\r/g, lineEnd) // Mac
        .replace(/\n/g, lineEnd); // Unix
    },
    removeNonWord(str) {
      return str.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, "");
    },
    parameterize(str, delimeter) {
      if (delimeter == null) {
        delimeter = "-";
      }
      str = replaceAccents(str);
      str = removeNonWord(str);
      str = trim(str)
        .replace(/ +/g, delimeter)
        .toLowerCase();
      return str;
    },
    formatDate(date, formatString = "dddd, MMMM Do YYYY") {
      return moment(date).format(formatString);
    },
    daysAgo(timestamp) {
      let today = moment(new Date());
      let date = moment(timestamp);
      return today.diff(date, "days");
    }
  }
};