import utils from "./utils.js";

import Question from "../models/Question";
import Questions from "../models/Questions";

export default {
  state: {
    questions: null,
    question: null
  },
  getters: {
    getQuestions: (state) => () => {
      return state.questions;
    },
    getWaypointQuestions: (state) => (waypoint) => {
      if (state.questions && waypoint) {
        return state.questions.filter(question => question.questionable_type == "Waypoint" && question.questionable_id == (waypoint?.id || waypoint));
      }
      return [];
    },
    getQuestion: (state) => () => {
      return state.question;
    }
  },
  actions: {
    async loadQuestions({commit}, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let waypoint = utils.try(params, "waypoint", null);
        if (refresh || (await Questions.count(waypoint, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let questions = await Questions.load(waypoint, search, offset, limit, deleted);
          if (questions && questions.length > 0) {
            await questions.store();
          }
        }
        let questions = await Questions.restore(waypoint, search, 0, offset + limit);
        commit("setQuestions", questions);
        return Promise.resolve(questions);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadQuestion({commit}, params = {}) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let waypoint = utils.try(params, "waypoint", null);
        let question = refresh ? null : await Question.restore(waypoint, params.question);
        if (question == null) {
          question = await Question.load(waypoint, params.question);
          if (question) {
            await question.store();
          }
        }
        commit("setQuestion", question);
        return Promise.resolve(question);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async clearQuestions({ commit }, params = {}) {
      await Questions.clear();
      commit("resetQuestions");
      return Promise.resolve();
    }
  },
  mutations: {
    setQuestions: (state, questions) => {
      state.questions = questions;
    },
    setQuestion: (state, question) => {
      state.question = question;
    },
    resetQuestions: (state) => {
      state.questions = null;
      state.question = null;
    }
  }
};