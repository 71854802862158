import Model from "./Model";

export default class Support extends Model {

  id = null;
  type = null;
  name = null;
  title = null;
  description = null;
  content = null;
  icon = null;
  ordinal = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`supports/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Support, `supports/${id}`);
  }

  static async load(id, resources = false) {
    return Model._load(Support, `supports/${id}.json?resources=${resources}`);
  }

}