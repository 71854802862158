<template>
  <splash-wrapper
    getterKey="changeTerm"
    :config="config"
    class="h-fit">
  </splash-wrapper>
</template>

<script>
import SplashWrapper from "./SplashWrapper";

export default {
  components: {
    SplashWrapper
  },
  computed: {
    config() {
      return [
        {
          title: this.$t("term_change_notification.title", {
            term: this.currentTerm?.name
          }),
          subtitle: this.$t("term_change_notification.title", {
            term: this.currentTerm?.name
          }),
          imageUrl: "splashes/term-change/term-change-notification.gif",
          icon: "bell-on"
        }
      ];
    },
    currentTerm() {
      return this.$store.getters.currentTerm();
    }
  }
};
</script>

<style lang="scss"></style>
