import Models from "./Models";
import Student from "./Student";

export default class Students extends Models {

  constructor(models = []) {
    super(Student, models);
  }

  static async clear() {
    return Models._clear("students");
  }

  static async count(classroom_id = null, teacher_id = null, search = "", offset = 0, limit = 10) {
    if (classroom_id && classroom_id.length > 0) {
      return Models._count("students/", classroom_id, offset, limit, "name", "classroom_ids");
    }
    if (teacher_id && teacher_id.length > 0) {
      return Models._count("students/", teacher_id, offset, limit, "name", "teacher_ids");
    }
    return Models._count("students/", search, offset, limit, "name", "name");
  }

  static async restore(classroom_id = null, teacher_id = null, search = "", offset = 0, limit = 10) {
    if (classroom_id && classroom_id.length > 0) {
      if (search && search.length > 0) {
        let students = await Models._restore(Students, Student, "students/", classroom_id, offset, 100, "name", "classroom_ids");
        return students.filter(student => student.name.toLowerCase().indexOf(search.toLowerCase()) != -1).slice(offset, offset + limit);
      }
      return Models._restore(Students, Student, "students/", classroom_id, offset, limit, "name", "classroom_ids");
    }
    if (teacher_id && teacher_id.length > 0) {
      if (search && search.length > 0) {
        let students = await Models._restore(Students, Student, "students/", teacher_id, offset, 100, "name", "teacher_ids");
        return students.filter(student => student.name.toLowerCase().indexOf(search.toLowerCase()) != -1).slice(offset, offset + limit);
      }
      return Models._restore(Students, Student, "students/", teacher_id, offset, limit, "name", "teacher_ids");
    }
    return Models._restore(Students, Student, "students/", search, offset, limit, "name", "name");
  }

  static async load(school_id = null, classroom_id, teacher_id = null, search = "", offset = 0, limit = 10, deleted = false) {
    if (classroom_id && classroom_id.length > 0) {
      return Models._load(Students, Student, `classrooms/${classroom_id}/students.json`, search, offset, limit, deleted);
    }
    if (teacher_id && teacher_id.length > 0) {
      return Models._load(Students, Student, `teachers/${teacher_id}/students.json`, search, offset, limit, deleted);
    }
    if (school_id && school_id.length > 0) {
      return Models._load(Students, Student, `schools/${school_id}/users.json?type=Student`, search, offset, limit, deleted);
    }
    return Models._load(Students, Student, "/users.json?type=Student", search, offset, limit, deleted);
  }

  static async invite(school_id, students) {
    return Models._create(Students, Student, `schools/${school_id}/students/bulk.json`, {
      students: students
    });
  }

  async loadStudents(params) {
    const path = "/students.json";
    const response = await this._getWithPagination(path, params);
    return response;
  }
}