import Model from "./Model";

export default class Question extends Model {

  id = null;
  type = null;
  icon = null;
  name = null;
  title = null;
  description = null;
  ordinal = null;
  options = null;
  required = false;
  questionable_id = null;
  questionable_type = null;
  support_id = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`questions/${this.questionable_id}/${this.id}`);
  }

  static async restore(waypoint, id) {
    let path = "questions/";
    if (waypoint && waypoint.length > 0) {
      path = `questions/${waypoint}/${id}`;
    }
    return Model._restore(Question, path);
  }

  static async load(waypoint, id) {
    let path = `questions/${id}.json`;
    if (waypoint && waypoint.length > 0) {
      path = `waypoints/${waypoint}/questions/${id}.json`;
    }
    return Model._load(Question, path);
  }

}