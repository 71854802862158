<template>
  <dialog-wrapper :title="$t('profile.edit_notifications')">
    <template #content>
      <div class="grid grid-cols-2 gap-12">
        <div class="col-span-2 mb-[22px]">
          <div class="gap-2 flex">
            <form-item
              ref="email-notifications"
              v-model:value="form.email_notifications"
              fieldId="email_notifications"
              type="switch"
              :errors="v$.email_notifications.$errors"
              class="flex-1">
            </form-item>
            <div class="p-2">
              <span class="py-2 font-bold">
                {{ $t('profile.email_notifications') }}
              </span>
              <p> Be notified when there are updates and notifications to your account. </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <WButton
        type="secondary"
        :label="$t('profile.cancel')"
        @click="dialogRef.close" />
      <WButton
        type="primary"
        :label="$t('profile.save')"
        :loading="loading"
        data-testid="save-button"
        @click="submitForm" />
    </template>
  </dialog-wrapper>
</template>

<script>
import {
  defineComponent,
  reactive
} from "vue";
import { required } from "@vuelidate/validators";
import Helpers from "@/mixins/Helpers";

import useVuelidate from "@vuelidate/core";
import { useStore } from "vuex";
import WButton from "@/components/wrappers/WButton";
import DialogWrapper from "@/components/wrappers/DialogWrapper";
import FormItem from "@/components/utils/form/FormItem";

export default defineComponent({
  name: "ProfileNotificationSettings",
  components: {
    WButton,
    DialogWrapper,
    FormItem
  },
  mixins: [
    Helpers
  ],
  inject: ["dialogRef"],
  setup() {
    const store = useStore();
    const user = store.getters.getUser();
    const form = reactive({
      email_notifications: user?.email_notifications
    });
    const rules = {
      email_notifications: { required }
    };

    const v$ = useVuelidate(rules, form, { $lazy: true });

    return {
      form,
      v$
    };
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async submitForm() {
      this.loading = true;
      try {
        await this.storeDispatch("userUpdateNotificationSettings", this.form.email_notifications);
        this.showToastNew("success", this.$t("profile.update_success_notifications"));
      }
      catch (error) {
        this.showToastNew("error", this.$t("profile.update_failed_notifications", error));
      }
      finally {
        this.loading = false;
        this.dialogRef.close();
      }
    }
  }
});
</script>

<style lang="scss">
/* Your styles here */
</style>
