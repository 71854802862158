import Models from "./Models";
import Term from "./Term";

export default class Terms extends Models {

  // t.uuid "district_id", null: false
  // t.string "name"
  // t.date "start_date"
  // t.date "end_date"
  // t.datetime "deleted_at"
  // t.datetime "created_at", precision: 6, null: false
  // t.datetime "updated_at", precision: 6, null: false
  // t.index ["district_id"], name: "index_terms_on_district_id"
  // t.index ["end_date"], name: "index_terms_on_end_date"
  // t.index ["start_date"], name: "index_terms_on_start_date"

  constructor(models = []) {
    super(Term, models);
  }

  static async clear() {
    return Models._clear("terms");
  }

  static async count(search = "", offset = 0, limit = 10) {
    return Models._count("terms", search, offset, limit);
  }

  static async restore(search = "", offset = 0, limit = 10) {
    return Models._restore(Terms, Term, "terms", search, offset, limit);
  }

  static async load(search = "", offset = 0, limit = 10, deleted = false) {
    return await Models._load(Terms, Term, "/terms.json", search, offset, limit, deleted);
  }
}
