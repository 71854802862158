import Server from "@/services/Server";
import Storage from "@/services/Storage";

export default class Models extends Array {

  constructor(modelClass, models = []) {
    super();
    if (models && models.length > 0) {
      for (let model of models) {
        this.push(new modelClass(model));
      }
    }
  }

  static async _clear(prefix) {
    return await Storage.clear(prefix);
  }

  async store() {
    for (let item of this) {
      item.store();
    }
  }

  static async _count(prefix, search = "", offset = 0, limit = 10, sort = "created_at", haystack = null) {
    let items = await Storage.search(prefix, search, haystack, offset, limit, sort);
    return items ? items.length : 0;
  }

  static async _restore(collectionClass, modelClass, prefix, search = "", offset = 0, limit = 10, sort = "created_at", haystack = null) {
    let collection = new collectionClass();
    let items = await Storage.search(prefix, search, haystack, offset, limit, sort);
    if (items && items.length > 0) {
      for (let item of items) {
        let model = new modelClass(item);
        collection.push(model);
      }
    }
    return collection;
  }

  static async _load(collectionClass, modelClass, path, search = "", offset = 0, limit = 10, deleted = false) {
    let collection = new collectionClass();
    let params = {
      offset: offset,
      limit: limit
    };
    if (search && search.length > 0) {
      params["search"] = search;
    }
    if (deleted) {
      params["deleted"] = deleted;
    }
    let items = await Server.get(path, params);
    if (items && items.length > 0) {
      for (let item of items) {
        let model = new modelClass(item);
        collection.push(model);
      }
    }
    return collection;
  }

  async _getWithPagination(path, params = {}) {
    let data = await Server.getWithPagination(path, params);
    return data;
  }

  static async _create(collectionClass, modelClass, path, params = {}) {
    let collection = new collectionClass();
    let items = await Server.post(path, params);
    if (items && items.length > 0) {
      for (let item of items) {
        let model = new modelClass(item);
        collection.push(model);
      }
    }
    return collection;
  }

  static path(...components) {
    let _path = [];
    for (let component of components) {
      if (component != null) {
        _path.push(component);
      }
    }
    return _path.join("/");
  }

  static params(params) {
    return Object.keys(params).map(key => key + "=" + (params[key] || "")).join("&");
  }

  static encodeParams(params) {
    let str = [];
    for (let p in params) {
      if (params.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    }
    return str.join("&");
  }
}