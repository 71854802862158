<template>
  <Sidebar
    v-model:visible="announcementsVisible"
    class="p-sidebar-md p-16"
    :baseZIndex="10000"
    position="right"
    @hide="onClose"
    @show="onOpen">
    <template #header>
      <div class="text-[22px] font-bold text-black">
        {{ $t("notification_modal.title") }}
      </div>
    </template>
    <div class="mt-[32px]">
      <template v-if="announcements && announcements.length > 0">
        <div
          v-for="announcement in announcements"
          :key="announcement.id">
          <div class="flex gap-[25px]">
            <div
              v-show="loadingImages"
              class="size-[170px] min-w-[170px] animate-pulse border-grey-4 bg-grey-4/80 shadow"></div>
            <img
              v-show="!loadingImages"
              class="size-[170px] min-w-[170px] border border-grey-4"
              :src="announcement.image"
              alt="attached image"
              @load="loadingImages = false"
              @error="loadingImages = false" />


            <div>
              <div class="flex flex-col">
                <div class="mt- mb-2 text-12 text-grey-3">
                  {{ dayjs(announcement.created_at).format("MMM. Do, YYYY") }}
                </div>
                <div class="flex items-center font-bold text-black">
                  <span class="text-18 mb-1">
                    {{ announcement.title }}
                  </span>
                  <div
                    v-if="!lastSeenDate || !dayjs(announcement.created_at).isSameOrBefore(lastSeenDate)"
                    class="h-5 px-2 py-0.5 bg-red-600 rounded gap-1.5 inline-flex w-10 items-center justify-start">
                    <div class="text-xs tracking-tight font-['Poppins'] font-normal text-white">
                      NEW
                    </div>
                  </div>
                </div>
                <div
                  class="html-content tinymce-content mt-8 text-14 font-normal text-black"
                  v-html="announcement.description">
                </div>
              </div>
              <div class="mt-8 w-full">
                <WButton
                  v-if="announcement?.type === 'Notification'"
                  type="primary"
                  :label="$t('announcement_sidebar.notification_button')"
                  class="ml-auto mt-12 w-fit"
                  @click="() => visitUrl(announcement.url)"/>
              </div>

            </div>

          </div>
          <Divider class="!my-30" />
        </div>
      </template>
      <template v-else>
        <div class="flex h-[65vh] flex-col justify-center text-center">
          <img
            class="mx-auto size-[300px]"
            src="@/assets/no-data.png"
            alt="No announcements or notifications" />
          <p class="mt-1">
            {{ $t('announcement_sidebar.no_announcements') }}
          </p>
        </div>
      </template>
    </div>
  </Sidebar>
</template>

<script>
import { defineComponent,
  ref,
  computed,
  onMounted,
  watch } from "vue";
import { useStore } from "vuex";
import { useDay } from "@/composables/utils/useDay";
import { useWindowSize } from "@vueuse/core";
import Sidebar from "primevue/sidebar";
import Divider from "primevue/divider";
import WButton from "@/components/wrappers/WButton";

export default defineComponent({
  name: "AnnouncementSidebar",
  components: {
    Sidebar,
    Divider,
    WButton
  },
  setup() {
    const store = useStore();
    const { dayjs } = useDay();
    const { width } = useWindowSize();

    const announcementsVisible = ref(false);
    const limit = ref(12);
    const loading = ref(false);
    const loadingImages = ref(true);

    const showAnnouncements = computed(() => store.getters.sidebars("announcements"));
    const lastSeenDate = computed(() => store.getters.announcementLastSeenAt());
    const announcements = computed(() => store.getters.getAnnouncements());
    const visitUrl = (url) => {
      window.location.href = url;
    };

    const loadAnnouncements = async(refresh = false, offset = 0) => {
      try {
        loading.value = true;
        await store.dispatch("loadAnnouncements", { refresh,
          offset,
          limit: limit.value });
      }
      catch (_error) {
        // Ignore Announcement Load Error
      }
      finally {
        loading.value = false;
      }
    };

    const onClose = () => {
      if (announcements.value && announcements.value.length > 0) {
        store.dispatch("setAnnouncementLastSeenAt", announcements.value[0].created_at);
        store.dispatch("setAnnouncementMenuNotification", true);
      }
    };
    const onOpen = () => {
      store.dispatch("setAnnouncementMenuNotification", false);
    };

    onMounted(() => {
      loadAnnouncements(true);
    });

    watch(showAnnouncements, (showAnnouncements) => {
      announcementsVisible.value = showAnnouncements;
    });
    watch(announcementsVisible, (announcementsVisible) => {
      store.dispatch("setSidebar", { key: "announcements",
        value: announcementsVisible });
    });

    return {
      announcementsVisible,
      announcements,
      lastSeenDate,
      dayjs,
      loadingImages,
      onClose,
      onOpen,
      visitUrl,
      width
    };
  }
});
</script>

<style scoped>
/* Add any additional styles here */
</style>
