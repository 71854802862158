<template>
  <Button
    v-bind="$attrs"
    :pt="getPresets()"
    :ptOptions="{ mergeSections: false, mergeProps: false }">
    <slot name="default" />
  </Button>
</template>

<script>
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  name: "WButton",
  components: {
    Button
  },
  props: {
    type: {
      type: String,
      default: "primary" // primary, secondary, tertiary, link, icon, nav
    },
    size: {
      type: String,
      default: "lg" // sm, lg
    },
    severity: {
      type: String,
      default: null // danger
    }
  },
  methods: {
    getPresets(type = this.type, size = this.size, severity = this.severity) {
      return {
        root: {
          class: [
            "rounded-[100px]",
            {
              "px-12": size === "sm" || type === "icon",
              "px-24": size !== "sm" && type !== "icon",
              // type
              "bg-dark-green text-white hover:bg-dark-green-70 disabled:bg-grey-5 disabled:text-grey-2": type === "primary",
              "bg-white text-dark-green border-1 border-solid border-dark-green hover:bg-dark-green-10 disabled:border-grey-4 disabled:text-grey-3": type === "secondary" || type === "icon",
              "bg-transparent text-dark-green hover:bg-grey-5 disabled:text-grey-3": type === "tertiary",
              "!p-0 underline text-dark-green bg-transparent hover:text-dark-green-70 whitespace-nowrap": type === "link",
              "whitespace-nowrap bg-grey-5 text-dark-green border-1 border-solid border-dark-green !px-16 !py-8 hover:bg-dark-green-10 ": type === "activeFilter",
              "whitespace-nowrap bg-white text-grey-1 border-1 border-solid border-grey-4 !px-16 !py-8 hover:bg-dark-green-10 hover:border-dark-green hover:!text-dark-green": type === "inactiveFilter",
              // size
              "h-40 py-8 text-14 tracking-[0.238px]": size === "lg",
              "h-[32px] py-6 text-12 tracking-[0.204px]": size === "sm",
              // severity
              "bg-red hover:bg-red-70": severity === "danger" && type === "primary",
              "text-red border-red hover:bg-red-10": severity === "danger" && type === "secondary",
              "text-red hover:text-red-70": severity === "danger" && (type === "link" || type === "tertiary")
            }
          ]
        },
        loadingIcon: {},
        icon: {},
        label: "pointer-events-none",
        badge: {}
      };
    },
    // used in other components (ie Dynamic Conform Dialogs)
    getRootClasses(type = this.type, size = this.size, severity = this.severity) {
      const presets = this.getPresets(type, size, severity).root.class;
      const classes = presets.flatMap(item => {
        if (typeof item === "object") {
          return Object.entries(item)
            .filter(([_, value]) => value)
            .map(([key, _]) => key);
        }
        return item;
      }).join(" ");
      return classes;
    }
  }
});
</script>
