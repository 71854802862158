import Model from "./Model";

export default class Announcement extends Model {

  id = null;
  type = null;
  title = null;
  description = null;
  icon = null;
  url = null;
  file = null;
  image = null;
  user_id = null;
  user_name = null;
  user_image = null;
  user_initials = null;
  school_id = null;
  school_name = null;
  classroom_id = null;
  classroom_name = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`announcements/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Announcement, `announcements/${id}`);
  }

  static async load(id) {
    return Model._load(Announcement, `announcements/${id}.json`);
  }

  async save(image_data = null, file_data = null, remove_image = null, remove_file = null) {
    if (this.id && this.id.length > 0) {
      return this._save(Announcement, `announcements/${this.id}.json`, {
        school_id: this.school_id,
        classroom_id: this.classroom_id,
        title: this.title,
        description: this.description,
        url: this.url,
        file: file_data,
        image: image_data,
        remove_file: remove_file,
        remove_image: remove_image
      });
    }
    else {
      return this._save(Announcement, "announcements.json", {
        school_id: this.school_id,
        classroom_id: this.classroom_id,
        title: this.title,
        description: this.description,
        url: this.url,
        file: file_data,
        image: image_data
      });
    }
  }

}