import utils from "./utils.js";

import Classroom from "@/models/Classroom";
import Classrooms from "@/models/Classrooms";

import Server from "@/services/Server";

const getDefaultState = () => {
  return {
    classrooms: null,
    classroom: null,
    classroomsDropdown: {},
    // new - paginated
    classroomsList: [],
    classroomsPagination: {
      requestHeaders: {
        page: 1,
        per_page: 10
      },
      responseHeaders: {}
    }
  };
};

export default {
  state: getDefaultState(),
  getters: {
    getClassrooms: (state) => (ids) => {
      if (state.classrooms && state.classrooms.length > 0 && ids && ids.length > 0) {
        return state.classrooms.filter(classroom => ids.includes(classroom.id));
      }
      return state.classrooms;
    },
    getTeacherClassrooms: (state) => (teacher) => {
      if (state.classrooms && state.classrooms.length > 0 && teacher) {
        return state.classrooms.filter(classroom => teacher.classroom_ids && teacher.classroom_ids.includes(classroom.id));
      }
      return [];
    },
    getStudentClassrooms: (state) => (student) => {
      if (state.classrooms && state.classrooms.length > 0 && student) {
        return state.classrooms.filter(classroom => student.classroom_ids && student.classroom_ids.includes(classroom.id));
      }
      return [];
    },
    getSchoolsClassrooms: (state) => (schools) => {
      if (state.classrooms && state.classrooms.length > 0 && schools && schools.length > 0) {
        return state.classrooms.filter(classroom => schools.includes(classroom.school_id));
      }
      return [];
    },
    getSchoolClassrooms: (state) => (school) => {
      if (state.classrooms && state.classrooms.length > 0 && school) {
        return state.classrooms.filter(classroom => classroom.school_id == school.id);
      }
      return [];
    },
    getClassroom: (state) => (id) => {
      if (state.classrooms && state.classrooms.length > 0 && id) {
        return state.classrooms.find(classroom => classroom.id == id);
      }
      return state.classroom;
    },
    classroomsDropdown(state) {
      return Object.entries(state.classroomsDropdown).map(([
        key, value
      ]) => ({
        id: key,
        name: value
      }));
    },
    // new - paginated
    classroomsList(state) {
      return state.classroomsList;
    },
    classroomsPagination(state) {
      return state.classroomsPagination;
    }
  },
  actions: {
    async loadClassrooms({
      commit, rootGetters
    }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 48);
        let ids = utils.try(params, "ids", null);
        let school = utils.try(params, "school", null) || rootGetters.getUser().current_school_id;
        let toolkit_id = utils.try(params, "toolkit_id", null);
        if (refresh || (await Classrooms.count(search, 0, offset + limit, ids)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let classrooms = await Classrooms.load(search, offset, limit, deleted, ids, school, toolkit_id);
          if (classrooms && classrooms.length > 0) {
            await classrooms.store();
          }
        }
        let classrooms = await Classrooms.restore(search, 0, offset + limit);
        commit("setClassrooms", classrooms);
        return Promise.resolve(classrooms);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadClassroom({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let classroom = refresh ? null : await Classroom.restore(params.classroom);
        if (classroom == null) {
          classroom = await Classroom.load(params.classroom, params.filters);
          if (classroom) {
            await classroom.store();
          }
        }
        commit("setClassroom", classroom);
        return Promise.resolve(classroom);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveClassroom({ commit }, params = {}) {
      try {
        let _classroom = new Classroom();
        _classroom.id = utils.try(params, "id", null);
        _classroom.school_id = utils.try(params, "school_id", null);
        _classroom.name = utils.try(params, "name", "");
        _classroom.description = utils.try(params, "description", "");
        _classroom.alternate_identifier = utils.try(params, "alternate_identifier", "");
        _classroom.min_grade = utils.try(params, "min_grade", "");
        _classroom.max_grade = utils.try(params, "max_grade", "");
        _classroom.exclude_toolkits = utils.try(params, "exclude_toolkits", "");
        _classroom.custom_class = utils.try(params, "custom_class", "");
        _classroom.tier = utils.try(params, "tier", null);
        let image_data = utils.try(params, "image_data", null);
        let remove_image = utils.try(params, "remove_image", null);
        let classroom = await _classroom.save(image_data, remove_image);
        await classroom.store();
        commit("setClassroom", classroom);
        return Promise.resolve(classroom);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteClassroom({commit}, params = {}) {
      let classroom = new Classroom();
      classroom.id = params.id || params.classroom_id;
      classroom.school_id = params.school_id;
      await classroom.delete();
      commit("removeClassroom", classroom);
      return Promise.resolve(null);
    },
    async addClassroomTeacher({ commit }, params = {}) {
      let _classroom = new Classroom();
      _classroom.id = params.id || params.classroom_id;
      _classroom.school_id = params.school_id;
      let classroom = await _classroom.addTeacher(params.teacher_id);
      await classroom.store();
      commit("setClassroom", classroom);
      return Promise.resolve(classroom);
    },
    async removeClassroomTeacher({ commit }, params = {}) {
      let _classroom = new Classroom();
      _classroom.id = params.id || params.classroom_id;
      _classroom.school_id = params.school_id;
      let classroom = await _classroom.removeTeacher(params.teacher_id);
      await classroom.store();
      commit("setClassroom", classroom);
      return Promise.resolve(classroom);
    },
    async addClassroomStudent({ commit }, params = {}) {
      let _classroom = new Classroom();
      _classroom.id = params.id || params.classroom_id;
      _classroom.school_id = params.school_id;
      let classroom = await _classroom.addStudent(params.student_id);
      await classroom.store();
      commit("setClassroom", classroom);
      return Promise.resolve(classroom);
    },
    async removeClassroomStudent({ commit }, params = {}) {
      let _classroom = new Classroom();
      _classroom.id = params.id || params.classroom_id;
      _classroom.school_id = params.school_id;
      let classroom = await _classroom.removeStudent(params.student_id);
      await classroom.store();
      commit("setClassroom", classroom);
      return Promise.resolve(classroom);
    },
    async loadClassroomsDropdown({ commit }) {
      let _classrooms = new Classrooms();
      const response = await _classrooms.loadClassroomsDropdown();
      commit("setClassroomsDropdown", response);
    },
    // new - paginated
    async loadClassroomsPaginated({ commit }, params) {
      let _classroom = new Classrooms();
      const response = await _classroom.loadClassrooms(params);
      commit("setClassroomsPaginated", response.data);
      commit("setClassroomsReponseHeaders", response.pagination);
    },
    setClassroomsRequestHeaders({ commit }, params) {
      commit("setClassroomsRequestHeaders", params);
    },
    // Custom Classrooms
    // NOTE: this needs refactoring, doesn't follow patterns used elsewhere
    // would be nice to move away from the model pattern and use a more traditional vuex/server wrapper pattern
    // but not a fan of how its being done here
    async inviteStudentsByEmail({ commit }, params) {
      const enable_notifications = utils.try(params, "enable_notifications", false);
      const students = utils.try(params, "students", null);
      const classroom_id = utils.try(params, "classroom_id", null);
      const url = process.env.VUE_APP_API_URL + "/classroom/invite_students_by_name_email.json";
      const response = await Server.post(url, {
        classroom_id,
        students,
        enable_notifications
      });

      return response[0];
    },
    async inviteStudentsById({ commit }, params) {
      const enable_notifications = utils.try(params, "enable_notifications", false);
      const student_ids = utils.try(params, "student_ids", null);
      const classroom_id = utils.try(params, "classroom_id", null);
      const url = process.env.VUE_APP_API_URL + "/classroom/invite_students_by_id.json";
      const response = await Server.post(url, {
        classroom_id,
        student_ids,
        enable_notifications
      });

      return response[0];
    },
    async submitClassroomInvitation({ commit }, params) {
      const code = utils.try(params, "code", null);
      const url = process.env.VUE_APP_API_URL + "/classroom/invite.json";
      const response = await Server.post(url, {
        code
      });
      return response[0];
    },
    async registerNewStudent({ commit }, params) {
      const email = utils.try(params, "email", null);
      const name = utils.try(params, "name", null);
      const code = utils.try(params, "code", null);
      const url = process.env.VUE_APP_API_URL + "/classroom/register_new_student.json";
      const response = await Server.post(url, {
        email,
        name,
        code
      });

      return response[0];
    },
    async acceptClassroomInvitation({ commit }, params) {
      const token = utils.try(params, "token", null);
      const email = utils.try(params, "email", null);
      const url = process.env.VUE_APP_API_URL + "/classroom/accept_classroom_invite.json";
      const response = await Server.post(url, {
        invitation_token: token,
        email
      });

      return response[0];
    },
    // Credentials
    async generateClassroomCredentials({ commit }, params) {
      const classroom_id = utils.try(params, "classroom_id", null);
      const url = process.env.VUE_APP_API_URL + `/classrooms/${classroom_id}/users/generate_credentials.json`;
      const response = await Server.get(url);

      return response;
    }
  },
  mutations: {
    setClassrooms: (state, classrooms) => {
      state.classrooms = classrooms;
    },
    setClassroom: (state, classroom) => {
      state.classroom = classroom;
      if (state.classrooms) {
        const index = state.classrooms.findIndex(_classroom => _classroom.id === classroom.id);
        if (index > -1) {
          state.classrooms.splice(index, 1, classroom);
        }
        else {
          state.classrooms.push(classroom);
        }
      }
    },
    removeClassroom(state, classroom) {
      if (state.classrooms) {
        const index =
          state.classrooms.findIndex(_classroom => _classroom.id === classroom.id);
        if (index > -1) {
          state.classrooms.splice(index, 1);
        }
      }
    },
    resetClassrooms: (state) => {
      Object.assign(state, getDefaultState());
    },
    setClassroomsDropdown(state, data) {
      state.classroomsDropdown = data;
    },
    // new - pagination
    setClassroomsPaginated: (state, data) => {
      state.classroomsList = data;
    },
    setClassroomsReponseHeaders: (state, headers) => {
      state.classroomsPagination.responseHeaders = headers;
    },
    setClassroomsRequestHeaders: (state, headers) => {
      state.classroomsPagination.requestHeaders = headers;
    }
  }
};