import Models from "./Models";
import School from "./School";

export default class Schools extends Models {

  constructor(...models) {
    super(...models);
  }

  static async clear() {
    return Models._clear("schools");
  }

  static async count(search = "", offset = 0, limit = 100) {
    return Models._count("schools", search, offset, limit, "name", "name,title,description");
  }

  static async restore(search = "", offset = 0, limit = 100) {
    return Models._restore(Schools, School, "schools", search, offset, limit, "name", "name,title,description");
  }

  static async load(teachers = false, students = false, classrooms = false, search = "", offset = 0, limit = 100, deleted = false) {
    return Models._load(Schools, School, `schools.json?teachers=${teachers}&students=${students}&classrooms=${classrooms}`, search, offset, limit, deleted);
  }

  async loadSchoolsDropdown() {
    const path = "/schools/dropdown.json";
    const response = await this._getWithPagination(path);
    return response?.data;
  }
}