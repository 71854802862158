<template>
  <i
    :class="classes"
    :aria-label="altText"
    :style="styles"
    :tabindex="button ? '0' : '-1'">
  </i>
</template>

<script lang="js">
import {
  defineComponent
} from "vue";

export default defineComponent({
  name: "FaIcon",
  props: {
    icon: {
      type: String,
      require: true
    },
    type: {
      type: String,
      required: false,
      default: "solid"
    },
    size: {
      type: String,
      required: false,
      default: "xxl"
    },
    // if passing in via jit need to do - ex. text-[#ccc]
    color: {
      type: String,
      required: false,
      default: null
    },
    iconClasses: {
      type: String,
      required: false,
      default: null
    },
    altText: {
      type: String,
      required: false,
      default: null
    },
    button: {
      type: Boolean,
      required: false,
      default: false
    },
    styles: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    iconType() {
      return this.type || "solid";
    },
    classes() {
      let classes = `fa-${this.iconType} fa-${this.icon} fa-${this.size}`;
      classes += this.color ? ` text-${this.color}` : "";
      if (this.iconClasses) {
        classes += ` ${this.iconClasses}`;
      }
      if (this.button) {
        classes += " hover:opacity-70 cursor-pointer";
      }
      return classes;
    }
  }
});
</script>