import Models from "./Models";
import Classroom from "./Classroom";

export default class Classrooms extends Models {

  constructor(models = []) {
    super(Classroom, models);
  }

  static async clear() {
    return Models._clear("classrooms");
  }

  static async count(search = "", offset = 0, limit = 10, ids = null) {
    if (ids && ids.length > 0) {
      return Models._count(Classrooms, Classroom, "classrooms", ids.join(","), offset, limit, "name", "id");
    }
    return Models._count(Classrooms, Classroom, "classrooms", search, offset, limit, "name", "name,title,description");
  }

  static async restore(search = "", offset = 0, limit = 10, ids = null) {
    if (ids && ids.length > 0) {
      return Models._restore(Classrooms, Classroom, "classrooms", ids.join(","), offset, limit, "name", "id");
    }
    return Models._restore(Classrooms, Classroom, "classrooms", search, offset, limit, "name", "name,title,description");
  }

  static async load(search = "", offset = 0, limit = 10, deleted = false, ids = null, school = null, toolkit_id = null) {
    let path = "classrooms.json";
    if (toolkit_id) {
      path = `classrooms.json?toolkit_id=${toolkit_id}`;
    }
    // NOTE: we may be able to get rid of this
    else if (ids) {
      path = `classrooms.json?ids=${ids.join(",")}`;
    }
    else if (school) {
      path = `schools/${school}/classrooms.json`;
    }
    return Models._load(Classrooms, Classroom, path, search, offset, limit, deleted);
  }

  async loadClassrooms(params) {
    const path = "/classrooms.json";
    const response = await this._getWithPagination(path, params);
    return response;
  }

  async loadClassroomsDropdown() {
    const path = "/classrooms/dropdown.json";
    const response = await this._getWithPagination(path);
    return response?.data;
  }
}