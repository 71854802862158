import Models from "./Models";
import Support from "./Support";

export default class Supports extends Models {

  constructor(models = []) {
    super(Support, models);
  }

  static async clear() {
    return Models._clear("supports");
  }

  static async count(type, search = "", offset = 0, limit = 10) {
    // TODO implement type check
    return Models._count("supports", search, offset, limit, "ordinal", "name,title,description");
  }

  static async restore(type, search = "", offset = 0, limit = 10) {
    let supports = await Models._restore(Supports, Support, "supports", search, offset, limit, "ordinal", "name,title,description");
    supports = supports.filter(support => support.type === type);
    return supports.slice(offset, offset + limit);
  }

  static async load(type = null, search = "", offset = 0, limit = 10, deleted = false) {
    return Models._load(Supports, Support, type ? `supports.json?type=${type}&active=true` : "supports.json?active=true", search, offset, limit, deleted);
  }

}