import Models from "./Models";
import Comment from "./Comment";

export default class Comments extends Models {

  constructor(models = []) {
    super(Comment, models);
  }

  static async clear() {
    return Models._clear("comments");
  }

  static async count(toolkit, lesson, search = "", offset = 0, limit = 50) {
    let path = Models.path("comments", toolkit, lesson);
    return Models._count(path, search, offset, limit, "-created_at");
  }

  static async restore(toolkit, lesson, search = "", offset = 0, limit = 50) {
    let path = Models.path("comments", toolkit, lesson);
    return Models._restore(Comments, Comment, path, search, offset, limit, "-created_at");
  }

  static async load(toolkit, lesson, recipient, search = "", offset = 0, limit = 50, deleted = false) {
    let path = `toolkits/${toolkit}/lessons/${lesson}/comments.json?recipient=${recipient}`;
    return Models._load(Comments, Comment, path, search, offset, limit, deleted);
  }

}