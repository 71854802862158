import Models from "./Models";
import Response from "./Response";

export default class Responses extends Models {

  constructor(models = []) {
    super(Response, models);
  }

  static async clear() {
    return Models._clear("responses");
  }

  static async count(toolkit = null, lesson = null, user = null, search = "", offset = 0, limit = 50) {
    let path = Models.path("responses", toolkit, lesson);
    return Models._count(path, user, offset, limit, "-created_at", "user_id");
  }

  static async restore(toolkit = null, lesson = null, user = null, search = "", offset = 0, limit = 50) {
    let path = Models.path("responses", toolkit, lesson);
    return Models._restore(Responses, Response, path, user, offset, limit, "-created_at", "user_id");
  }

  static async load(toolkit = null, lesson = null, user = null, search = "", offset = 0, limit = 50, deleted = false) {
    let params = {
      order: "-created_at"
    };
    if (toolkit) {
      params["toolkit_id"] = toolkit;
    }
    if (lesson) {
      params["lesson_id"] = lesson;
    }
    if (user) {
      params["user_id"] = user;
    }
    let path = [
      "responses.json", Models.params(params)
    ].join("?");
    return Models._load(Responses, Response, path, search, offset, limit, deleted);
  }

}