import Invitation from "../models/Invitation";

export default {
  state: {},
  getters: {},
  actions: {
    async acceptInvitation({ commit }, params) {
      try {
        commit("resetUsers");
        let user = await Invitation.accept(params.token, params.name, params.password, params.email, params.image_data);
        await user.store();
        commit("setUser", user);
        return Promise.resolve(user);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async reinviteInvitation({ commit }, params) {
      try {
        let result = await Invitation.reinvite(params.user_id);
        return Promise.resolve(result);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async resendInvitation({ commit }, params) {
      try {
        let result = await Invitation.resend(params.email);
        return Promise.resolve(result);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {}
};
