<template>
  <Sidebar
    id="profile"
    v-model:visible="profileVisible"
    :class="[width < 1024 ? 'p-sidebar-lg' : 'p-sidebar-md', 'p-16']"
    :baseZIndex="10000"
    position="right">
    <template #header>
      <span class="text-[22px] font-bold">
        {{ $t("profile.title") }}
      </span>
    </template>
    <div class="text-center">
      <Avatar
        v-if="showImage"
        :image="user.image"
        class="profile-avatar"
        shape="circle"
        alt="user image"
        @error="showImage = false;" />
      <Avatar
        v-else
        :label="user.initials"
        class="profile-avatar"
        shape="circle" />
      <div class="text-[22px] font-semibold text-black">
        {{ user.name }}
      </div>
      <div
        class="mt-6 text-[18px] text-black">
        {{ $t(`user_type_key.${activeRole.toLowerCase()}`, activeRole) }}
      </div>
    </div>
    <div class="m-16 mt-[48px] flex flex-col text-16">
      <div>
        <div class="flex justify-between">
          <span class="font-semibold">
            {{ $t("profile.email_notifications") }}
          </span>
          <WButton
            type="link"
            class="ml-6 cursor-pointer font-semibold text-dark-green underline"
            @click="notificationSettings()">
            {{ $t("profile.edit") }}
          </WButton>
        </div>
        <Divider class="my-4" />
      </div>
      <div>
        <div class="flex justify-between">
          <span class="font-semibold">
            {{ $t("profile.email") }}
          </span>
          <span
            class="ml-6 truncate"
            :title="user.email">
            {{ user.email }}
          </span>
        </div>
        <Divider class="my-4" />
      </div>
      <div
        class="mb-16 border-b border-grey-4">
        <div class="mb-8 flex justify-between">
          <div class="mt-8 h-24 font-semibold">
            {{ $t("profile.language") }}
          </div>
          <Dropdown
            id="locale"
            v-model="$i18n.locale"
            class="border-none"
            :options="locales"
            optionLabel="key"
            optionValue="value"
            :placeholder="$t('profile_form.language_select')"
            @change="changeLocale" />
        </div>
      </div>
      <div v-if="user.toolkits_count">
        <div class="flex justify-between">
          <span class="font-semibold">
            {{ $t("profile.toolkits") }}
          </span>
          <span>{{ user.toolkits_count }} {{ user.toolkits_count === 1 ? $t("curriculum") : $t("curriculums") }}</span>
        </div>
        <Divider class="my-4" />
      </div>
      <div v-if="user.classrooms_count">
        <div class="flex justify-between">
          <span class="font-semibold">
            {{ $t("profile.classrooms") }}
          </span>
          <span>{{ pluralize(user.classrooms_count, "classroom") }}</span>
        </div>
        <Divider class="my-4" />
      </div>
      <div v-if="user.isStudent() === false && user.students_count">
        <div class="flex justify-between">
          <span class="font-semibold">
            {{ $t("profile.students") }}
          </span>
          <span>{{ pluralize(user.students_count, "student") }}</span>
        </div>
        <Divider class="my-4" />
      </div>
      <div class="mb-[80px]">
        <div class="flex justify-between">
          <span>
            <span class="font-semibold">
              {{ $t("profile.accessibility_widget") }}
            </span>
            <tooltip
              :popperTitle="$t('dashboard.login_activity_popper_title')"
              :popperContent="$t('profile.accessibility_widget_tooltip')"
              class="inline"
              openDelay="0"
              closeDelay="1000"
              :hover="true">
              <div>
                <fa-icon
                  class="ml-6 text-16"
                  icon="circle-info"
                  size="sm"
                  color="grey-3">
                </fa-icon>
              </div>
            </tooltip>
          </span>
          <span>
            <InputSwitch
              v-model="user.accessibility_tools"
              class="p-inputswitch-primary"
              @change="toggleAccessibilityTools" />
          </span>
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import {
  defineComponent
} from "vue";
import {
  mapGetters
} from "vuex";

import Sidebar from "primevue/sidebar";
import Avatar from "primevue/avatar";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";

import Helpers from "@/mixins/Helpers";
import Filters from "@/mixins/Filters";
import Tooltip from "@/components/utils/Tooltip";
import FaIcon from "@/components/utils/FaIcon";
import ProfileNotificationSettings from "@/components/sidebars/ProfileNotificationSettings";
import WButton from "@/components/wrappers/WButton";

import {
  useWindowSize
} from "@vueuse/core";

export default defineComponent({
  name: "ProfileSidebar",
  components: {
    Sidebar,
    Avatar,
    Divider,
    Dropdown,
    InputSwitch,
    Tooltip,
    FaIcon,
    WButton
  },
  mixins: [
    Helpers,
    Filters
  ],
  setup() {
    const { width } = useWindowSize();

    return {
      width
    };
  },
  data() {
    return {
      profileVisible: false,
      locales: [
        {
          key: "English",
          value: "en"
        },
        {
          key: "Spanish",
          value: "es"
        }
      ],
      showImage: false
    };
  },
  computed: {
    ...mapGetters([
      "activeRole"
    ]),
    user() {
      return this.$store.getters.getUser();
    },
    showProfile() {
      return this.$store.getters.sidebars("profile");
    },
    currentToolkit() {
      return this.$route.params.toolkit;
    }
  },
  watch: {
    showProfile() {
      this.profileVisible = this.showProfile;
    },
    profileVisible() {
      this.storeDispatch("setSidebar", {
        key: "profile",
        value: this.profileVisible
      });
    }
  },
  mounted() {
    this.showImage = this.user?.image;
  },
  methods: {
    notificationSettings() {
      this.openDialog(ProfileNotificationSettings, "medium");
    },
    async changeLocale({ value }) {
      if (this.user?.locale === value) {
        return;
      }
      try {
        this.showLoading("page_layout.changing_language");
        await this.storeDispatch("userLocale", {
          locale: this.$i18n.locale
        });
      }
      catch (error) {
        this.showError(this.$t("profile_form.error"), error);
      }
    },
    async toggleAccessibilityTools() {
      try {
        this.showLoading("page_layout.saving");
        await this.storeDispatch("userAccessibilityTools", this.user.accessibility_tools); // value already changed by model linkage
      }
      catch (error) {
        this.showError(this.$t("profile_form.error"), error);
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.profile-avatar {
  @apply text-white bg-dark-purple mr-[2px] my-[18px] rounded-full;
  font-size: 34px;
  height: 100px;
  width: 100px;
}

:deep(#locale) {
  .p-dropdown-label {
    @apply shadow-none text-black opacity-80 #{!important};
  }
}
</style>