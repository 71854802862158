import Models from "./Models";
import Question from "./Question";

export default class Questions extends Models {

  constructor(models = []) {
    super(Question, models);
  }

  static async clear() {
    return Models._clear("questions");
  }

  static async count(waypoint, search = "", offset = 0, limit = 10) {
    let path = "questions/";
    if (waypoint && waypoint.length > 0) {
      path = `questions/${waypoint}/`;
    }
    return Models._count(path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async restore(waypoint, search = "", offset = 0, limit = 10) {
    let path = "questions/";
    if (waypoint && waypoint.length > 0) {
      path = `questions/${waypoint}/`;
    }
    return Models._restore(Questions, Question, path, search, offset, limit, "ordinal", "name,title,description");
  }

  static async load(waypoint, search = "", offset = 0, limit = 10, deleted = false) {
    let path = "questions.json";
    if (waypoint && waypoint.length > 0) {
      path = `waypoints/${waypoint}/questions.json`;
    }
    return Models._load(Questions, Question, path, search, offset, limit, deleted);
  }

}