import utils from "./utils.js";

export default {
  state: {
    timePeriod: {
      default: "current_term"
    },
    schoolFilter: {
      default: "all"
    },
    classFilter: {
      default: "all"
    },
    accountManager: {
      default: "all"
    },
    selectedAssessmentId: null
  },
  getters: {
    timePeriod(state, rootGetters) {
      return state.timePeriod[rootGetters.getUserId()] || state.timePeriod.default;
    },
    accountManager(state, rootGetters) {
      return state.accountManager[rootGetters.getUserId()] || state.accountManager.default;
    },
    schoolFilter(state, rootGetters) {
      return state.schoolFilter[rootGetters.getUserId()] || state.schoolFilter.default;
    },
    classFilter(state, rootGetters) {
      return state.classFilter[rootGetters.getUserId()] || state.classFilter.default;
    },
    isMultiItemFilter: () => (filter) => {
      return filter === "all" || filter.startsWith("grade:");
    },
    mostViewed: (state) => (id) => {
      return id ? state.mostViewed.find(r => r.id === id) : state.mostViewed;
    },
    selectedAssessmentId(state) {
      return state.selectedAssessmentId;
    }
  },
  actions: {
    setTimePeriod({
      commit,
      rootGetters
    }, value) {
      commit("setDashboardUiDefaults", {
        key: "timePeriod",
        userId: rootGetters.getUserId(),
        value
      });
    },
    setAccountManager({
      commit,
      rootGetters
    }, value) {
      commit("setDashboardUiDefaults", {
        key: "accountManager",
        userId: rootGetters.getUserId(),
        value
      });
    },
    setSchoolFilter({
      commit,
      rootGetters
    }, value) {
      commit("setDashboardUiDefaults", {
        key: "schoolFilter",
        userId: rootGetters.getUserId(),
        value: value ? value : "all"
      });
    },
    setClassFilter({
      commit,
      rootGetters
    }, value) {
      commit("setDashboardUiDefaults", {
        key: "classFilter",
        userId: rootGetters.getUserId(),
        value: value ? value : "all"
      });
    }
  },
  mutations: {
    setSelectedAssessmentId: (state, id) => {
      state.selectedAssessmentId = id;
    },
    setDashboardUiDefaults(state, {
      key, userId, value, subkey
    }) {
      utils.setUserStoreKey(state, {
        key,
        userId,
        value,
        subkey
      });
    }
  }
};
