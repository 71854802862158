import utils from "./utils.js";

import Comment from "../models/Comment";
import Comments from "../models/Comments";

export default {
  state: {
    comments: []
  },
  getters: {
    getLessonComments: (state) => (lesson_id) => {
      if (state.comments && state.comments.length > 0 && lesson_id) {
        return state.comments.filter(comment => comment.lesson_id == lesson_id);
      }
      return null;
    },
    getComments: (state) => () => {
      return state.comments;
    },
    getComment: (state) => (comment_id) => {
      if (state.comments && state.comments.length > 0) {
        return state.comments.find(comment => comment.id == comment_id);
      }
      return null;
    }
  },
  actions: {
    async loadComments({commit}, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 100);
        let toolkit = utils.try(params, "toolkit", null);
        let lesson = utils.try(params, "lesson", null);
        let recipient = utils.try(params, "recipient", null);
        if (refresh || (await Comments.count(toolkit, lesson, recipient, search, 0, offset + limit)) == 0) {
          let deleted = utils.try(params, "deleted", false);
          let comments = await Comments.load(toolkit, lesson, recipient, search, offset, limit, deleted);
          if (comments && comments.length > 0) {
            await comments.store();
          }
        }
        let comments = await Comments.restore(toolkit, lesson, search, 0, offset + limit);
        commit("setComments", comments);
        return Promise.resolve(comments);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadComment({commit}, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let toolkit = utils.try(params, "toolkit", null);
        let lesson = utils.try(params, "lesson", null);
        let comment = refresh ? null : await Comment.restore(toolkit, lesson, params.id);
        if (comment == null) {
          comment = await Comment.load(toolkit, lesson, params.id);
          if (comment) {
            await comment.store();
          }
        }
        commit("setComment", comment);
        return Promise.resolve(comment);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveComment({commit}, params) {
      try {
        let _comment = new Comment();
        _comment.id = utils.try(params, "id", null);
        _comment.body = params.body;
        _comment.sender_id = params.sender_id;
        _comment.sender_name = params.sender_name;
        _comment.recipient_id = params.recipient_id;
        _comment.recipient_name = params.recipient_name;
        _comment.toolkit_id = params.toolkit_id;
        _comment.chapter_id = params.chapter_id;
        _comment.lesson_id = params.lesson_id;
        _comment.type = utils.try(params, "type", "Comment");
        let comment = await _comment.save();
        await comment.store();
        commit("setComment", comment);
        return Promise.resolve(comment);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteComment({commit}, params = {}) {
      let comment = new Comment();
      comment.id = params.id;
      await comment.delete();
      commit("removeComment", comment);
      return Promise.resolve(null);
    }
  },
  mutations: {
    setComments: (state, comments) => {
      state.comments = comments;
    },
    setComment: (state, comment) => {
      const index = state.comments.findIndex(_comment => _comment.id === comment.id);
      if (index > -1) {
        state.comments.splice(index, 1, comment);
      }
      else {
        state.comments.push(comment);
      }
    },
    removeComment: (state, comment) => {
      if (state.comments) {
        const index = state.comments.findIndex(_comment => _comment.id === comment.id);
        if (index > -1) {
          state.comments.splice(index, 1);
        }
      }
    },
    resetComments: (state) => {
      state.comments = [];
    }
  }
};