import utils from "./utils.js";

import Announcements from "../models/Announcements";

import {
  useDay
} from "@/composables/utils/useDay.js";
const { dayjs } = useDay();

export default {
  state: {
    announcements: null,
    announcement: null
  },
  getters: {
    getAnnouncements: (state) => (limit) => {
      if (state.announcements && state.announcements.length > 0 && limit != null) {
        return state.announcements.slice(0, limit);
      }
      return state.announcements;
    },
    getAnnouncement: (state) => (id) => {
      if (state.announcement) {
        return state.announcement;
      }
      if (state.announcements && state.announcements.length > 0) {
        return state.announcements.find(announcement => announcement.id == id);
      }
      return null;
    },
    getUnreadAnnouncementCount: (state, getters) => () => {
      const announcementLastSeenAt = getters.announcementLastSeenAt();
      if (state.announcements && state.announcements.length > 0 && announcementLastSeenAt !== null) {
        return state.announcements.filter(announcement => !dayjs(announcement.created_at).isSameOrBefore(announcementLastSeenAt)).length;
      }
      else if (announcementLastSeenAt === null) {
        // All announcements are unread
        return state.announcements?.length;
      }
      return 0;
    }
  },
  actions: {
    async loadAnnouncements({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 50);
        let deleted = utils.try(params, "deleted", false);
        if (refresh || (await Announcements.count(search, 0, offset + limit)) == 0) {
          let announcements = await Announcements.load(search, offset, limit, deleted);
          if (announcements && announcements.length > 0) {
            await announcements.store();
          }
        }
        let announcements = await Announcements.restore(search, 0, offset + limit);
        commit("setAnnouncements", announcements);
        return Promise.resolve(announcements);
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  },
  mutations: {
    setAnnouncements: (state, announcements) => {
      state.announcements = announcements;
    },
    setAnnouncement: (state, announcement) => {
      state.announcement = announcement;
      if (state.announcements) {
        const index = state.announcements.findIndex(_announcement => _announcement.id === announcement.id);
        if (index > -1) {
          state.announcements.splice(index, 1, announcement);
        }
        else {
          state.announcements.unshift(announcement);
        }
      }
    },
    resetAnnouncements: (state) => {
      state.announcements = null;
      state.announcement = null;
    }
  }
};