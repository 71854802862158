import Models from "./Models";
import Teacher from "./Teacher";

export default class Teachers extends Models {

  constructor(models = []) {
    super(Teacher, models);
  }

  static async clear() {
    return Models._clear("teachers");
  }

  static async count(school_id = null, classroom_id = null, search = "", offset = 0, limit = 10) {
    if (classroom_id && classroom_id.length > 0) {
      return Models._count("teachers/", classroom_id, offset, limit, "name", "classroom_ids");
    }
    if (school_id && school_id.length > 0) {
      return Models._count(`school/${school_id}/teachers/`, search, offset, limit);
    }
    return Models._count("teachers/", search, offset, limit, "name", "name");
  }

  static async restore(classroom_id = null, search = "", offset = 0, limit = 10) {
    if (classroom_id && classroom_id.length > 0) {
      if (search && search.length > 0) {
        let teachers = await Models._restore(Teachers, Teacher, "teachers/", classroom_id, offset, 100, "name", "classroom_ids");
        return teachers.filter(teacher => teacher.name.toLowerCase().indexOf(search.toLowerCase()) != -1).slice(offset, offset + limit);
      }
      return Models._restore(Teachers, Teacher, "teachers/", classroom_id, offset, limit, "name", "classroom_ids");
    }
    return Models._restore(Teachers, Teacher, "teachers/", search, offset, limit, "name", "name");
  }

  static async load(school_id = null, classroom_id = null, search = "", offset = 0, limit = 10, deleted = false) {
    if (classroom_id && classroom_id.length > 0) {
      return Models._load(Teachers, Teacher, `classrooms/${classroom_id}/teachers.json`, search, offset, limit, deleted);
    }
    if (school_id && school_id.length > 0) {
      return Models._load(Teachers, Teacher, `schools/${school_id}/users.json?type=Teacher`, search, offset, limit, deleted);
    }
    return Models._load(Teachers, Teacher, "/users.json?type=Teacher", search, offset, limit, deleted);
  }

  static async invite(school_id, teachers) {
    return Models._create(Teachers, Teacher, `schools/${school_id}/teachers/bulk.json`, {
      teachers: teachers
    });
  }
}
