import Model from "./Model";

export default class Term extends Model {
  id = null;
  type = null;
  name = null;
  start_date = null;
  end_date = null;
  academic_year = null;
  district_id = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`terms/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Term, `terms/${id}`);
  }

  static async load(id) {
    let term = Model._load(Term, `terms/${id}`);
    return term;
  }

  async save() {
    let formatStart = new Date(this.start_date);
    let formatEnd = new Date(this.end_date);

    let path = this.id && this.id.length > 0 ? `districts/${this.district_id}/terms/${this.id}.json` : `districts/${this.district_id}/terms.json`;
    return this._save(Term, path, {
      name: this.name,
      start_date: formatStart,
      end_date: formatEnd,
      academic_year: this.academic_year
    });
  }

  async delete() {
    await this._delete(`districts/${this.district_id}/terms/${this.id}.json`);
    await this._remove(`districts/${this.district_id}/terms/${this.id}`);
    return null;
  }
}
