import utils from "./utils.js";

import Student from "../models/Student";
import Students from "../models/Students";

const getDefaultState = () => {
  return {
    classroomStudents: null,
    students: null,
    student: null,
    // new - paginated
    studentsList: [],
    studentsPagination: {
      requestHeaders: {
        page: 1,
        per_page: 10
      },
      responseHeaders: {}
    }
  };
};

export default {
  state: getDefaultState(),
  getters: {
    getStudents: (state) => (ids) => {
      if (state.students && state.students.length > 0 && ids && ids.length > 0) {
        return state.students.filter(student => ids.includes(student.id));
      }
      return state.students;
    },
    getClassroomStudents: (state) => (classroom) => {
      if (state.classroomStudents?.length > 0 && classroom) {
        return state.classroomStudents.filter(student => student.classroom_ids && student.classroom_ids.includes(classroom.id || classroom));
      }
      return [];
    },
    getStudent: (state) => () => {
      return state.student;
    },
    // new - paginated
    studentsList(state) {
      return state.studentsList;
    },
    studentsPagination(state) {
      return state.studentsPagination;
    }
  },
  actions: {
    async loadStudents({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 48);
        let school = utils.try(params, "school", null);
        let classroom = utils.try(params, "classroom", null);
        let teacher = utils.try(params, "teacher", null);
        if (refresh || (await Students.count(school, classroom, teacher, search, 0, offset + limit)) == 0) {
          let students = await Students.load(school, classroom, teacher, search, offset, limit);
          if (students && students.length > 0) {
            await students.store();
          }
        }
        let students = await Students.restore(classroom, teacher, search, 0, offset + limit);
        classroom ? commit("setClassroomStudents", students) : commit("setStudents", students);
        return Promise.resolve(students);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadStudent({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let student = refresh ? null : await Student.restore(params.id);
        if (student == null) {
          student = await Student.load(params.id);
          if (student) {
            await student.store();
          }
        }
        commit("setStudent", student);
        return Promise.resolve(student);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveStudent({ commit }, params = {}) {
      try {
        let _student = new Student();
        _student.id = utils.try(params, "id", null);
        _student.name = utils.try(params, "name", null);
        _student.email = utils.try(params, "email", null);
        _student.toolkit_ids = utils.try(params, "toolkit_ids", []);
        _student.classroom_ids = utils.try(params, "classroom_ids", []);
        let student = await _student.save();
        await student.store();
        commit("setStudent", student);
        return Promise.resolve(student);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async inviteStudent({ commit }, params = {}) {
      try {
        let school_id = utils.try(params, "school_id", null);
        let classroom_id = utils.try(params, "classroom_id", null);
        let toolkit_ids = utils.try(params, "toolkit_ids", []);
        let _student = new Student();
        _student.name = utils.try(params, "name", null);
        _student.email = utils.try(params, "email", null);
        let student = await _student.invite(school_id, classroom_id, toolkit_ids);
        await student.store();
        commit("setStudent", student);
        return Promise.resolve(student);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async inviteStudents({commit}, params = {}) {
      try {
        let school_id = utils.try(params, "school_id", null);
        let students = await Students.invite(school_id, params.students);
        if (students && students.length > 0) {
          await students.store();
        }
        return Promise.resolve(students);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteStudent({commit}, params = {}) {
      let student = new Student();
      student.id = params.id || params.student_id;
      await student.delete();
      commit("removeStudent", student);
      return Promise.resolve(null);
    },
    // new - paginated
    async loadStudentsPaginated({ commit }, params) {
      try {
        let _students = new Students();
        const response = await _students.loadStudents(params);
        commit("setStudentsPaginated", response.data);
        commit("setStudentsReponseHeaders", response.pagination);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    setStudentsRequestHeaders({ commit }, params) {
      commit("setStudentsRequestHeaders", params);
    }
  },
  mutations: {
    setStudents: (state, students) => {
      state.students = students;
    },
    setClassroomStudents: (state, students) => {
      state.classroomStudents = students;
    },
    setStudent: (state, student) => {
      state.student = student;
      if (state.students) {
        const index = state.students.findIndex(_student => _student.id === student.id);
        if (index > -1) {
          state.students.splice(index, 1, student);
        }
        else {
          state.students.push(student);
        }
      }
    },
    removeStudent(state, student) {
      if (state.students) {
        const index =
          state.students.findIndex(_student => _student.id === student.id);
        if (index > -1) {
          state.students.splice(index, 1);
        }
      }
      if (state.classroomStudents) {
        const index =
          state.classroomStudents.findIndex(_student => _student.id === student.id);
        if (index > -1) {
          state.classroomStudents.splice(index, 1);
        }
      }
    },
    resetStudents: (state) => {
      Object.assign(state, getDefaultState());
    },
    // new - pagination
    setStudentsPaginated: (state, data) => {
      state.studentsList = data;
    },
    setStudentsReponseHeaders: (state, headers) => {
      state.studentsPagination.responseHeaders = headers;
    },
    setStudentsRequestHeaders: (state, headers) => {
      state.studentsPagination.requestHeaders = headers;
    }
  }
};