import Model from "./Model";

export default class Comment extends Model {

  id = null;
  type = null;
  body = null;
  sender_id = null;
  sender_name = null;
  recipient_id = null;
  recipient_name = null;
  toolkit_id = null;
  chapter_id = null;
  lesson_id = null;
  created_at = null;
  updated_at = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    let path = Model.path("comments", this.toolkit_id, this.lesson_id, this.id);
    return this._store(path);
  }

  static async restore(toolkit, lesson, id) {
    let path = `comments/${toolkit}/${lesson}/${id}`;
    return Model._restore(Comment, path);
  }

  static async load(toolkit, lesson, id) {
    let path = `toolkits/${toolkit}/lessons/${lesson}/comments/${id}.json`;
    return Model._load(Comment, path);
  }

  async save() {
    let path = this.id && this.id.length > 0 ? `comments/${this.id}.json` : "comments.json";
    return this._save(Comment, path, {
      recipient_id: this.recipient_id,
      toolkit_id: this.toolkit_id,
      chapter_id: this.chapter_id,
      lesson_id: this.lesson_id,
      body: this.body
    });
  }

  async delete() {
    await this._delete(`comments/${this.id}.json`);
    await this._remove(`comments/${this.id}`);
    return null;
  }
}