if (window.location.href.includes("projectwayfinder.com")) {
  window.location.href = window.location.href.replace("projectwayfinder.com", "withwayfinder.com");
}
import { createApp } from "vue";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import DialogService from "primevue/dialogservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import "./theme/plugins/primevue.scss";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import mixpanel from "mixpanel-browser";
import ahoy from "ahoy.js";

import { VueQueryPlugin } from "@tanstack/vue-query";
import vue3GoogleLogin from "vue3-google-login";
import VueSnip from "vue-snip";
import {
  ResizeObserver as Polyfill
} from "@juggle/resize-observer";
import {
  VueCookieNext
} from "vue-cookie-next";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import locales from "./locales";
import "./theme";
import "./pwa";

const app = createApp(App);

window.ResizeObserver = window.ResizeObserver || Polyfill;

// ChunkLoadError
window.onerror = (errorMessage, _chunkUrl, _line, _column, _error) => {
  if (errorMessage.toLowerCase().indexOf("unexpected token '<'") > -1 && navigator.onLine){
    window.location.reload();
  }
};

ahoy.configure({
  urlPrefix: new URL(process.env.VUE_APP_API_URL).origin,
  visitsUrl: "/ahoy/visits",
  eventsUrl: "/ahoy/events",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: true,
  trackVisits: true,
  cookies: true,
  cookieDomain: new URL(process.env.VUE_APP_API_URL).hostname.split(".").reverse().splice(0, 2).reverse().join("."),
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
  visitParams: {},
  withCredentials: false,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60 // 2 years
});

if (process.env.VUE_APP_MIXPANEL_API_KEY && process.env.VUE_APP_MIXPANEL_API_KEY.length > 0) {
  mixpanel.init(process.env.VUE_APP_MIXPANEL_API_KEY, {
    debug: process.env.VUE_APP_DEBUG == "true"
  });
}

app.config.globalProperties.$loading = null;

app.use(PrimeVue);
app.use(ConfirmationService);
app.use(DialogService);
app.use(ToastService);
app.directive("tooltip", Tooltip);

app.use(VueQueryPlugin);
app.use(VueSnip);
app.use(VueCookieNext);
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
});

app.use(locales);
app.use(store);
app.use(router);

app.mount("#app");
