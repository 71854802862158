import utils from "./utils.js";

import Teacher from "../models/Teacher";
import Teachers from "../models/Teachers";

export default {
  state: {
    classroomTeachers: null,
    teachers: null,
    teacher: null
  },
  getters: {
    teachers: (state) => (ids) => {
      if (state.teachers && state.teachers.length > 0 && ids && ids.length > 0) {
        return state.teachers.filter(teacher => ids.includes(teacher.id));
      }
      return state.teachers;
    },
    getClassroomTeachers: (state) => (classroom) => {
      if (state.classroomTeachers && state.classroomTeachers.length > 0 && classroom) {
        return state.classroomTeachers.filter(teacher => teacher.classroom_ids && teacher.classroom_ids.includes(classroom.id));
      }
      return [];
    },
    getTeacher: (state) => (id) => {
      if (state.teachers && state.teachers.length > 0 && id) {
        return state.teachers.find(teacher => teacher.id == id);
      }
      return state.teacher;
    }
  },
  actions: {
    async loadTeachers({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let search = utils.try(params, "search", null);
        let offset = utils.try(params, "offset", 0);
        let limit = utils.try(params, "limit", 48);
        let school = utils.try(params, "school", null);
        let classroom = utils.try(params, "classroom", null);
        if (refresh || (await Teachers.count(school, classroom, search, 0, offset + limit)) == 0) {
          let teachers = await Teachers.load(school, classroom, search, offset, limit);
          if (teachers && teachers.length > 0) {
            await teachers.store();
          }
        }
        let teachers = await Teachers.restore(classroom, search, 0, offset + limit);
        classroom ? commit("setClassroomTeachers", teachers) : commit("setTeachers", teachers);
        return Promise.resolve(teachers);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async loadTeacher({ commit }, params) {
      try {
        let refresh = utils.try(params, "refresh", false);
        let teacher = refresh ? null : await Teacher.restore(params.id);
        if (teacher == null) {
          teacher = await Teacher.load(params.id);
          if (teacher) {
            await teacher.store();
          }
        }
        commit("setTeacher", teacher);
        return Promise.resolve(teacher);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async saveTeacher({ commit }, params = {}) {
      try {
        let _teacher = new Teacher();
        _teacher.id = utils.try(params, "id", null);
        _teacher.name = utils.try(params, "name", null);
        _teacher.email = utils.try(params, "email", null);
        _teacher.classroom_ids = utils.try(params, "classroom_ids", []);
        let teacher = await _teacher.save();
        await teacher.store();
        commit("setTeacher", teacher);
        return Promise.resolve(teacher);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async inviteTeacher({ commit }, params = {}) {
      try {
        let school_id = utils.try(params, "school_id", null);
        let classroom_id = utils.try(params, "classroom_id", null);
        let _teacher = new Teacher();
        _teacher.name = utils.try(params, "name", null);
        _teacher.email = utils.try(params, "email", null);
        let teacher = await _teacher.invite(school_id, classroom_id);
        await teacher.store();
        commit("setTeacher", teacher);
        return Promise.resolve(teacher);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async inviteTeachers({commit}, params = {}) {
      try {
        let school_id = utils.try(params, "school_id", null);
        let teachers = await Teachers.invite(school_id, params.teachers);
        if (teachers && teachers.length > 0) {
          await teachers.store();
        }
        return Promise.resolve(teachers);
      }
      catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteTeacher({commit}, params = {}) {
      let teacher = new Teacher();
      teacher.id = params.id || params.teacher_id;
      await teacher.delete();
      commit("removeTeacher", teacher);
      return Promise.resolve(null);
    }
  },
  mutations: {
    setTeachers: (state, teachers) => {
      state.teachers = teachers;
    },
    setClassroomTeachers: (state, teachers) => {
      state.classroomTeachers = teachers;
    },
    setTeacher: (state, teacher) => {
      state.teacher = teacher;
      if (state.teachers) {
        const index = state.teachers.findIndex(_teacher => _teacher.id === teacher.id);
        if (index > -1) {
          state.teachers.splice(index, 1, teacher);
        }
        else {
          state.teachers.push(teacher);
        }
      }
    },
    removeTeacher(state, teacher) {
      if (state.teachers) {
        const index =
          state.teachers.findIndex(_teacher => _teacher.id === teacher.id);
        if (index > -1) {
          state.teachers.splice(index, 1);
        }
      }
      if (state.classroomTeachers) {
        const index =
          state.classroomTeachers.findIndex(_teacher => _teacher.id === teacher.id);
        if (index > -1) {
          state.classroomTeachers.splice(index, 1);
        }
      }
    },
    resetTeachers: (state) => {
      state.classroomTeachers = null;
      state.teachers = null;
      state.teacher = null;
    }
  }
};