import Models from "./Models";
import DataExport from "./DataExport";

export default class DataExports extends Models {

  constructor(models = []) {
    super(DataExport, models);
  }

  static async clear() {
    return Models._clear("data_exports");
  }

  static async count(search = "", offset = 0, limit = 10) {
    return Models._count("data_exports", search, offset, limit);
  }

  static async restore(search = "", offset = 0, limit = 10) {
    return Models._restore(DataExports, DataExport, "data_exports", search, offset, limit);
  }

  static async load(district_id, search = "", offset = 0, limit = 10, deleted = false) {
    return Models._load(DataExports, DataExport, `districts/${district_id}/data_exports.json`, search, offset, limit, deleted);
  }

}