import Models from "./Models";
import Announcement from "./Announcement";

export default class Announcements extends Models {

  constructor(models = []) {
    super(Announcement, models);
  }

  static async clear() {
    return Models._clear("announcements");
  }

  static async count(search = "", offset = 0, limit = 10) {
    return Models._count("announcements", search, offset, limit, "-updated_at", "title,description");
  }

  static async restore(search = "", offset = 0, limit = 10) {
    return Models._restore(Announcements, Announcement, "announcements", search, offset, limit, "-updated_at", "title,description");
  }

  static async load(search = "", offset = 0, limit = 10, deleted = false) {
    return Models._load(Announcements, Announcement, "notifications/user-notifications.json", search, offset, limit, deleted);
  }

}