import Model from "./Model";

export default class Student extends Model {

  id = null;
  type = null;
  name = null;
  image = null;
  initials = null;
  created_at = null;
  updated_at = null;
  invited_at = null;
  accepted_at = null;
  sign_in_at = null;
  classrooms_count = null;
  students_count = null;
  responses_count = null;

  toolkits_ids = [];
  school_ids = [];
  classroom_ids = [];
  teacher_ids = [];
  student_ids = [];

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async store() {
    return this._store(`students/${this.id}`);
  }

  static async restore(id) {
    return Model._restore(Student, `students/${id}`);
  }

  static async load(id) {
    return Model._load(Student, `students/${id}.json`);
  }

  async save() {
    return this._save(Student, `students/${this.id}.json`, {
      name: this.name,
      email: this.email,
      toolkit_ids: this.toolkit_ids,
      classroom_ids: this.classroom_ids
    });
  }

  async invite(school_id, classroom_id, toolkit_ids) {
    let path = classroom_id && classroom_id.length > 0
      ? `schools/${school_id}/classrooms/${classroom_id}/students/invite.json`
      : `schools/${school_id}/students/invite.json`;
    return this._save(Student, path, {
      toolkit_ids: toolkit_ids,
      name: this.name,
      email: this.email
    });
  }

  async delete() {
    await this._delete(`students/${this.id}.json`);
    await this._remove(`students/${this.id}`);
    return null;
  }

}