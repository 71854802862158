import Models from "./Models";
import Answer from "./Answer";

export default class Answers extends Models {

  constructor(models = []) {
    super(Answer, models);
  }

  static async clear() {
    return Models._clear("answers");
  }

  static async count(waypoint, user, search = "", offset = 0, limit = 10) {
    let path = "answers/";
    if (waypoint && waypoint.length > 0) {
      path = `answers/${waypoint}/`;
    }
    if (user && user.length > 0) {
      return Models._count(path, user, offset, limit, "updated_at", "user_id");
    }
    return Models._count(path, search, offset, limit, "updated_at", "question_name");
  }

  static async restore(waypoint, user, search = "", offset = 0, limit = 10) {
    let path = "answers/";
    if (waypoint && waypoint.length > 0) {
      path = `answers/${waypoint}/`;
    }
    if (user && user.length > 0) {
      return await Models._restore(Answers, Answer, path, user, offset, limit, "updated_at", "user_id");
    }
    return await Models._restore(Answers, Answer, path, search, offset, limit, "updated_at", "question_name");
  }

  static async load(waypoint, user, search = "", offset = 0, limit = 10, deleted = false) {
    let path = "answers.json";
    if (waypoint && waypoint.length > 0) {
      path = `waypoints/${waypoint}/answers.json`;
    }
    if (user && user.length > 0) {
      let params = {
        user_id: user
      };
      path = `${path}?${Models.params(params)}`;
    }
    return Models._load(Answers, Answer, path, search, offset, limit, deleted);
  }

  static async loadByWaypoint(waypoint, classroom, search = "", offset = 0, limit = 80, deleted = false) {
    let params = {
      search: search,
      offset: offset,
      limit: limit,
      classroom_id: classroom
    };
    let path = `waypoints/${waypoint}/student_answers.json?${Models.params(params)}`;
    return Models._load(Answers, Answer, path, search, offset, limit, deleted);
  }
}