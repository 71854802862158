<template>
  <term-change-notification></term-change-notification>
</template>

<script>
import {
  defineComponent
} from "vue";

import TermChangeNotification from "./TermChangeNotification";

export default defineComponent({
  components: {
    TermChangeNotification
  }
});
</script>
