import Model from "./Model";

export default class Lti extends Model {

  id = null;
  name = null;

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
  }

  async getLtiTools(district) {
    const path = `/lti/districts/${district}/tools.json`;
    const response = await this._get(path);
    return response;
  }

  async getLtiTool(district, tool) {
    const path = `/lti/districts/${district}/tools/${tool}.json`;
    const response = await this._get(path);
    return response;
  }

  async createLtiTool(district, params) {
    const path = `/lti/districts/${district}/tools.json`;
    const response = await this._post(Lti, path, params);
    return response;
  }

  async updateLtiTool(district, tool, params) {
    const path = `/lti/districts/${district}/tools/${tool}.json`;
    const response = await this._patch(Lti, path, params);
    return response;
  }

  async deleteLtiTool(district, tool) {
    const path = `/lti/districts/${district}/tools/${tool}.json`;
    const response = await this._delete(path);
    return response;
  }

  async ltiLogin(district, tool) {
    const path = `/lti/districts/${district}/tools/${tool}/login_initiations.json`;
    const response = await this._post(Lti, path);
    return response;
  }

  async ltiLaunches(district, tool) {
    const path = `/lti/districts/${district}/tools/${tool}/launches.json`;
    const response = await this._post(Lti, path);
    return response;
  }

  async ltiCreateDeepLink(district, tool) {
    const path = `/lti/districts/${district}/tools/${tool}/deep_link_launches.json`;
    const response = await this._post(Lti, path);
    return response;
  }

  async ltiLaunchDeepLink(district, tool) {
    const path = `/lti/districts/${district}/tools/${tool}/deep_link_launches/launches.json`;
    const response = await this._post(Lti, path);
    return response;
  }

  async getLtiKeys(district, tool) {
    const path = `/lti/districts/${district}/tools/${tool}/well-known/jwks.json`;
    const response = await this._get(path);
    return response;
  }

  async createLtiAssignment(district, tool, launch, params) {
    let form = document.createElement("form");
    form.setAttribute("method", "post");

    form.setAttribute("action", `${process.env.VUE_APP_LTI_URL}/lti/districts/${district}/tools/${tool}/deep_link_launches/${launch}/launch`);
    form._submit_function_ = form.submit;
    let hiddenField = document.createElement("input");
    hiddenField.setAttribute("type", "hidden");
    hiddenField.setAttribute("name", params.lesson_ids ? "lesson_ids" : "waypoint_ids");
    hiddenField.setAttribute("value", params.lesson_ids || params.waypoint_ids);
    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form._submit_function_();
  }
}
