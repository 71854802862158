import Model from "./Model";
import User from "./User";
import Server from "../services/Server";
import Analytics from "../services/Analytics";

export default class Password extends Model {
  constructor(data = {}) {
    super(data);
  }

  static async forgot(email) {
    let url = new URL("/passwords/forgot.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      email: email
    });
    if (response) {
      return response.message;
    }
    return null;
  }

  static async reset(token, password) {
    let url = new URL("/passwords/reset.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      token: token,
      password: password
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

}